<template>
  <div style="padding: 20px;">
    <el-form ref="form" :model="form" :rules="rules">
    
    <!-- <span>Name or Email:</span>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <el-form-item>
          <el-input
            v-model="form.term"
            placeholder="Name or Email"
            size="small"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row> -->

    <span>Filter by:</span>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
        <el-form-item prop="campus">
          <el-select v-model="form.campus" placeholder="Campus" size="large" style="width: 100%;">
            <el-option :label="campus.value" :value="campus.id" v-for="campus in $store.state.campuses" :key="campus.id"></el-option>
          </el-select>
        </el-form-item>
        
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
        <el-form-item prop="course">
          <search-course-component :course.sync="form.course" style="width:100%" :campus="form.campus" ></search-course-component>
        </el-form-item>
      
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
        <el-form-item prop="verdict">
          <el-select v-model="form.verdict" placeholder="Verdict" size="large" style="width: 100%;">
            <el-option :label="verdict.value" :value="verdict.id" v-for="verdict in $store.state.results" :key="verdict.id"></el-option>
          </el-select>
        </el-form-item>
      
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
        <el-form-item prop="exam_mode">
          <el-select v-model="form.exam_mode" placeholder="Exam mode" size="large" style="width: 100%;">
            <el-option :label="exam_mode.value" :value="exam_mode.id" v-for="exam_mode in $store.state.exam_mode" :key="exam_mode.id"></el-option>
          </el-select>
        </el-form-item>
      
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2">
        <el-form-item>
          <el-button  type="success" 
          @click="searchResults()"
          size="medium"
          :loading="loading"
          icon="el-icon-search"
          name="upload">Go</el-button>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="5">
        <el-button type="warning" 
        @click="expoortResults"
        size="medium"
        :loading="loading"
        icon="el-icon-download"
        name="upload">Export</el-button>
      </el-col>
    </el-row>

    <el-card>
      <el-table size='small' :data="results" stripe style="width: 100%">
        <el-table-column prop="control_no" label="ECN" ></el-table-column>
        <el-table-column prop="lastname" label="Last Name" ></el-table-column>
        <el-table-column prop="middlename" label="Middle Name" ></el-table-column>
        <el-table-column prop="firstname" label="First Name" ></el-table-column>
        <el-table-column prop="campus" label="Campus" ></el-table-column>
        <el-table-column prop="course" label="Course" >
          <template slot-scope="scope">
          <el-popover
            v-if="scope.row.course"
            placement="top-start"
            :title="scope.row.course.code"
            width="200"
            trigger="hover"
            :content="scope.row.course.description">
            <el-link type="info" slot="reference">{{ scope.row.course.code }}</el-link>
          </el-popover>
             
         </template>
        </el-table-column>
        <el-table-column prop="overall_rating" label="Overall Rating" >
          <template slot-scope="scope">
          <el-popover
            v-if="scope.row.applicant_exam_detail"
            placement="top-start"
            :title="'Avg GPA: '+scope.row.applicant_exam_detail.converted_gpa"
            width="200"
            trigger="hover"
            :content="'Exam Score: '+scope.row.applicant_exam_detail.cat_score">
            <el-link type="info" slot="reference">{{ scope.row.applicant_exam_detail.overall_rating }}</el-link>
          </el-popover>
             
         </template>
        </el-table-column>
        <el-table-column prop="result" label="Result" >
          <template slot-scope="scope">
          <el-popover
            v-if="scope.row.applicant_exam_detail"
            placement="top-start"
            width="200"
            trigger="hover">
            <el-link type="info" slot="reference">{{ scope.row.applicant_exam_detail.result }}</el-link>
          </el-popover>
             
         </template>
        </el-table-column>
        <el-table-column  label="Exam Mode" >
          <template slot-scope="scope">
          <el-popover
            v-if="scope.row.applicant_exam_detail"
            placement="top-start"
            width="200"
            trigger="hover">
            <el-link type="info" slot="reference">{{ scope.row.applicant_exam_detail.exam_mode }}</el-link>
          </el-popover>
             
         </template>
        </el-table-column>
        <el-table-column label="Action" >
          <template slot-scope="scope">
            <el-button
            @click="showReview(scope.row.id)"
            type="text"
            size="small"
            style="margin-right: 5px">View</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="margin-top-20"
        background
        layout="prev, pager, next"
        :total="meta.total"
        @current-change="pageChanged"
        :current-page="meta.page"
        >
        </el-pagination>
    </el-card>

    </el-form>
  </div>

</template>

<style>
  .right {
    text-align: right;
  }

  .add-btn {
    margin-bottom: 1rem;
  }
</style>

<script>

// import { ref } from 'vue'
import { Applicant } from '@/api_services/applicant'
import SearchCourseComponent from '@/components/common/SearchCourseComponent.vue'

export default {
  components: { SearchCourseComponent },
  name: 'examresults',
  data() {
    const validateCourseStatus = (rule, value, callback) =>{

      if (!value) {
        callback(new Error('Degree Program is required'));
        return;
      }

      callback();
    };
    return {
      showDialog: false,
      loading: false,
      form: {
        term: null,
        campus: null,
        course: {
          id: null,
        },
        verdict: null,
        exam_mode: null
      },
      results: [],
      meta: {
            page: 1,
            pages: 1,
            total: 30,
            per_page: 10,
        },
      rules: {
          campus: [
            { required: true, message: 'Campus is required', trigger: 'blur' },
          ],
          course: [
            { trigger: 'blur' , validator: validateCourseStatus},
          ],
          verdict: [
            { required: true, message: 'Verdict is required', trigger: 'blur' },
          ],
          exam_mode: [
            { required: true, message: 'Exam Mode is required', trigger: 'blur' },
          ],
      }
    }
  },
  created() {
    console.log(this.form);
    this.getResults();
  },
  methods: {
    pageChanged(val) {
      this.meta.page = val;
      this.searchResults();
    },
    addApplicantForm() {
      this.showDialog = true;
    },
    getResults(){
      // this.results = null;
    },
    searchResults(){
      console.log(this.form);
      this.loading = true;
      Applicant.getResults({
        params: {page: this.meta.page, campus: this.form.campus, course_id: this.form.course.id, verdict: this.form.verdict, exam_mode: this.form.exam_mode, term: this.term},
      }).then((result) => {
        this.results = result.data.data;
        console.log(result.data.data);
        console.log(result.data.meta);
        this.meta = {
          page: result.data.meta.current_page,
          total: result.data.meta.total,
          per_page: result.data.meta.per_page,
          pages: result.data.meta.last_page,
        };
        console.log(this.meta);
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.results = [];
        console.log(error.response);
      });
    },
    expoortResults(){

    },
    showReview(item) {
      let routeData = this.$router.resolve({name: 'SelectedApplicant', params: { id: item }});
      window.open(routeData.href, '_blank')
    },
  }
  
}
</script>
