import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Landing from '../views/Landing.vue'
import Start from '../views/Start.vue'
import Application from '../views/Application.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import PageNotFound from '../views/PageNotFound.vue'
import GuestApplicant from '../views/GuestApplicant.vue'

import Main from '../views/private/pages/Main.vue'
import Dashboard from '../views/private/pages/dashboard/Dashboard.vue'
import ApplicantsHome from '../views/private/pages/applicants/ApplicantsHome.vue'

import ApplicantsPage from '../views/private/pages/applicants/ApplicantsPage.vue'
import SelectedApplicantPage from '../views/private/pages/applicants/SelectedApplicantPage.vue'
import SelectedSchedulePage from '../views/private/pages/schedules/SelectedSchedulePage.vue'

import Schedules from '../views/private/pages/schedules/Schedules.vue'
import Courses from '../views/private/pages/courses/Courses.vue'
import Users from '../views/private/pages/settings/users/Users.vue'
import Settings from '../views/private/pages/settings/Settings.vue'
import store from '../store/index'

import F2fapplicants from '../views/private/pages/facetofaceapplicants/F2fApplicantsPage.vue'
import Configure from '../views/private/pages/settings/conf/Configure.vue'

import UploadResults from '../views/private/pages/results/UploadResults.vue'
import ExamResults from '../views/private/pages/results/ExamResults.vue'
import RankResults from '../views/private/pages/results/RankResults.vue'
import Proctors from '../views/private/pages/proctors/Proctors.vue'

// import EditConfigComponent from '../views/private/pages/settings/conf/EditConfigComponent.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Landing',
    meta: { public: true},
    component: Landing
  },
  {
    path: '/404',
    name: 'PageNotFound',
    meta: { public: true},
    component: PageNotFound
  },
  {
    path: '/start',
    name: 'Start',
    meta: { public: true},
    component: Start
  },
  {
    path: '/guest',
    name: 'Guest',
    meta: { public: true},
    component: GuestApplicant
  },
  {
    path: '/login',
    name: 'Login',
    meta: { public: true},
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { public: false},
    component: Logout
  },
  {
    path: '/application',
    name: 'Application',
    meta: { public: true},
    component: Application
  },
  {
    path: '/main',
    name: 'MainView',
    component: Main,
    meta: { public: false},
    redirect: '',
    children: [
      { path: '', component: Dashboard, name: 'main', meta: { public: false}, },
      { 
        path: 'applicants', component: ApplicantsHome, name: 'ApplicantsHome', redirect: '', meta: { public: false},
        children: [
          { path: '', component: ApplicantsPage, name: 'applicants', meta: { public: false}, },
          { path: ':id', component: SelectedApplicantPage, name: 'SelectedApplicant', meta: { public: false}, },
        ]
      },
      { path: 'f2fapplicants', component: F2fapplicants, name: 'f2fapplicants', meta: { public: false}, },
      { path: 'schedules', component: Schedules, name: 'schedules', meta: { public: false}, },
      { path: 'schedules/:id', component: SelectedSchedulePage, name: 'SelectedSchedule', meta: { public: false}, },
      { path: 'courses', component: Courses, name: 'courses', meta: { public: false}, },
      { path: 'settings', component: Settings, name: 'settings', meta: { public: false}, },
      { path: 'settings/users', component: Users, name: 'users', meta: { public: false}, },
      { path: 'settings/configure', component: Configure, name: 'configure', meta: { public: false}, },
      { path: 'uploadresults', component: UploadResults, name: 'uploadresults', meta: { public: false}, },
      { path: 'examresults', component: ExamResults, name: 'examresults', meta: { public: false}, },
      { path: 'rankresults', component: RankResults, name: 'rankresults', meta: { public: false}, },
      { path: 'proctors', component: Proctors, name: 'proctors', meta: { public: false}, },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ((to.name === 'settings' || to.name === 'users') && (store?.state?.savedUser as any).role !== 'admin') {
    next({name: 'main'});
    return;
  }

  next();

});

export default router
