<template>
  <div>
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item label="Name or Email">
        <el-input
          v-model="searchForm.name"
          placeholder="Name or Email"
          size="small"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="Campus" v-if="$store.state.savedUser.role === 'admin'">
        <el-select
          v-model="searchForm.campus"
          placeholder="Campus"
          size="small"
        >
          <el-option label="All" value=""></el-option>
           <el-option :label="campus.value" :value="campus.id" v-for="campus in $store.state.campuses" :key="campus.id"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="Gender">
        <el-select
          v-model="searchForm.gender"
          placeholder="Gender"
          size="small"
        >
          <el-option label="All" value=""></el-option>
           <el-option :label="gender.value" :value="gender.id" v-for="gender in $store.state.genders" :key="gender.id"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-select v-model="searchForm.status" placeholder="Filter Status" size="small">
          <el-option
            v-for="(status,index) in $store.state.applicant_statuses"
            :key="index"
            :label="status"
            :value="index">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select v-model="searchForm.catyear"  placeholder="CAT year 2022" size="small" :disabled="true">
          <el-option
          v-for="(catyear,index) in $store.state.search_catyear"
            :key="index"
            :label="catyear"
            :value="index">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="onSearch"
          size="small"
          :loading="loading"
          icon="el-icon-search"
          >Search</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-tag class="el-tag-dark-green">Total: {{ meta.total }}</el-tag>
      </el-form-item>
      <el-form-item>
        <el-dropdown split-button type="primary" size="small" @command="exportApplicants" v-if="$store.state.savedUser.role === 'admin'">
          Export Applicants
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="campus.id" :key="campus.id" v-for="campus in $store.state.campuses">{{ campus.id }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" size="small" @click="exportApplicants($store.state.savedUser.campus)" v-if="$store.state.savedUser.role !== 'admin'">Export Applicants</el-button>
      </el-form-item>
      
    </el-form>


    <el-table :data="applicants" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="70"></el-table-column>
      <el-table-column prop="firstname" label="First Name"></el-table-column>
      <el-table-column prop="middlename" label="Middle Name"></el-table-column>
      <el-table-column prop="lastname" label="Last Name"></el-table-column>
      <el-table-column prop="email" label="Email" width="200"></el-table-column>
      <el-table-column prop="contact_no" label="Contact No"></el-table-column>
   
      <el-table-column prop="gender" label="Sex">
         <template slot-scope="scope">
             {{ showGender(scope.row)}}
         </template>
      </el-table-column>
      <el-table-column prop="campus" label="Campus"></el-table-column>
 
      <el-table-column prop="course_id" label="Degree program" width="130">
        <template slot-scope="scope">
          <el-popover
            v-if="scope.row.course"
            placement="top-start"
            :title="scope.row.course.code"
            width="200"
            trigger="hover"
            :content="scope.row.course.description">
            <el-link type="info" slot="reference">{{ scope.row.course.code }}</el-link>
          </el-popover>
             
         </template>
      </el-table-column>
      <el-table-column prop="status" label="Status" width="200">
        <template slot-scope="scope">
          <el-alert
            v-if="scope.row.final_status != 'REJECTED'"
            :title="scope.row.final_status"
            :type="getAlertStatus(scope.row.final_status)"
            :closable="false">
          </el-alert>

          <el-popover
            v-if="scope.row.final_status == 'REJECTED'"
            placement="top-start"
            title="Reason(s)"
            width="200"
            trigger="hover"
            :content="scope.row.reject_remarks">
            <el-alert
            slot="reference"
            :title="scope.row.final_status"
            :type="getAlertStatus(scope.row.final_status)"
            :closable="false">
          </el-alert>
          </el-popover>

         </template>
      </el-table-column>

      <el-table-column fixed="right" label="Action" width="120">
        <template slot-scope="scope">
   
          <el-button
            @click="showReview(scope.row)"
            type="text"
            size="small"
            style="margin-right: 5px"
            >Review</el-button
          > 
          <el-badge :value="scope.row.revert_ctr" class="item">
          </el-badge>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="Unlock" width="70" v-if="$store.state.savedUser.role === 'admin'">
        <template slot-scope="scope">
   
          <el-button
            @click="unlockSplash(scope.row.id)"
            type="text"
            style="margin-left: 10px"
            v-if="scope.row.locked == 1"
            ><i class="el-icon-unlock"></i></el-button>

        </template>
      </el-table-column>

    </el-table>
    <el-pagination
      class="margin-top-20"
      background
      layout="prev, pager, next"
      :total="meta.total"
      @current-change="pageChanged"
      :current-page="meta.page"
    >
    </el-pagination>
 
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars

import { Applicant } from "@/api_services/applicant";

export default {
  name: "Applicants",
  data() {
    return {
      loading: false,
      searchForm: {},
      drawer: false,
      review: false,
      applicant: {
        id: 1,
        firstname: "Micco",
        middlename: "Augusto",
        lastname: "Barua",
        extension: "",
        email: "masdasd@gmail.com",
        contact_no: "0923232323",
        birth_place: "Ormoc",
        gender: "Male",
        citizenship: "Filipino",
        civil_status: "Single",
        course_id: "SAasdas",
      },
      applicants: [],
      meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 10,
      },
      selected: null,
    };
  },
  created: function () {
    this.onSearch();
  },
  methods: {
    getAlertStatus(status) {
      let index = 0
      this.$store.state.final_status.forEach(function(final, i){
        if (final == status) index = i;
      })
      return  this.$store.state.alert_status[index]
    },
    showGender(item) {
      let string = ""
      this.$store.state.genders.forEach(function(gender){
        if (gender.id == item.gender ) {
          string = gender.value
        }
      })

      return string
    },
    ShowCitizenship(item) {
      let string = ""
      this.$store.state.citizenships.forEach(function(c){
        if (c.id == item.citizenship ) {
          string = c.value
        }
      })

      return string
    },
     showCivilStatus(item) {
      let string = ""
      this.$store.state.statuses.forEach(function(s){
        if (s.id == item.civil_status ) {
          string = s.value
        }
      })

      return string
    },
    onSearch() {
      this.meta.page = 1;
      this.loading = true;
      this.getApplicants();
    },
    getApplicants() {
      Applicant.get({
        params: { page: this.meta.page, term: this.searchForm.name, status: this.searchForm.status },
      })
        .then((result) => {
          this.applicants = result.data.data;
          this.meta = {
            page: result.data.meta.current_page,
            total: result.data.meta.total,
            per_page: result.data.meta.per_page,
            pages: result.data.meta.last_page,
          };
          this.loading = false;
          // console.log(result.data.data);
        })
        .catch((error) => {
          this.loading = false;
          this.applicants = [];
        });
    },
    edit(item) {
      this.drawer = true;
      this.selected = Object.assign({}, item);
    },
    showReview(item) {
      this.$router.push({ name: 'SelectedApplicant', params: { id: item.id } })
      // this.review = true;
      // this.selected = Object.assign({}, item);
    },
    unlockSplash(id) {
      this.$confirm("Are you sure you want to unlock applicant ID: " + id + "?")
      .then((_) => {
        this.unlock(id);
        })
        .catch((_) => {});
      console.log(id);
    },
    handleClose(done) {
      done();
    },
    onDelete(item) {
      let name = item.firstname + " " + item.lastname;
      this.$confirm("Are you sure you want to remove " + name + " ?")
        .then((_) => {
          this.remove(item);
        })
        .catch((_) => {});
    },
    remove(item) {
      console.log(item);
    },
    pageChanged(val) {
      this.meta.page = val;
      this.getApplicants();
    },
    exportApplicants(campus) {

      Applicant.exportCsv({
        campus: campus.toLowerCase()
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'applicants_'+ campus +'.csv');
        document.body.appendChild(link);
        link.click();
      });
    },
    unlock(id) {
      Applicant.unLock(id)
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
    }
  },
};
</script>