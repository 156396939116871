<template>
    <div> 
        <el-row :gutter="20" style="padding:10px">
          

           <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            
            <el-card class="box-card">
              <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item label="Date:">
                  <el-date-picker
                    v-model="searchForm.date"
                    placeholder="Date"
                    size="small"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item>
                  <el-button
                    type="primary"
                    @click="onSearch"
                    size="small"
                    :loading="loading"
                    icon="el-icon-search"
                    >Search</el-button
                  >
                </el-form-item>
                
              </el-form>
              <hr>
              <small style="color:#9090a5">*Select schedule to view / update proctors and generate exam roster</small>
              <el-table ref="singleTable" :data="schedules" highlight-current-row
              @current-change="handleCurrentChange"
              style="width: 100%; margin-top: 20px;" size="small">
                <el-table-column prop="date" label="Date" width="90"></el-table-column>
                <el-table-column prop="time_start" label="Start Time" width="90"></el-table-column>
                <el-table-column prop="time_end" label="End Time" width="90"></el-table-column>

                <el-table-column label="No. of proctors assigned" align="center" width="110" >
                  <template slot-scope="scope" >
                    <b>{{ scope.row.assigned_proctor_ids !== null ? scope.row.assigned_proctor_ids.length : "0" }}</b>
                  </template>
                </el-table-column>

                <el-table-column prop="slots" label="Applicants" align="center" width="90"></el-table-column>
                <el-table-column
                  prop="tag"
                  label="Exam roster"
                  width="100"
                  align="center">
                  <template slot-scope="scope">
                    <b v-if="scope.row.assigned_proctor_ids !== null" style="color:#67C23A">Yes</b>
                    <b v-if="scope.row.assigned_proctor_ids === null" style="color:#F56C6C">No</b>
                  </template>
                </el-table-column>
              </el-table>

            </el-card>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  
            <el-col>

              <el-card class="box-card" 
                v-if="hiddenCardTemp" >
                <div slot="header" class="clearfix">
                  <span> </span>
                  <br/>
                </div>
                <el-table size="small">
                </el-table>
              </el-card>
              

              <el-card class="box-card" v-if="hiddenCard">
                <!-- <el-row> -->
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                      <span>Assigned proctors for <b><u>{{ selected_date_time }}</u></b></span>
                      <br/>
                  </el-col>
                  
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-button
                    style="width:100%;"
                      type="success"
                      @click="handleClickProctor"
                      size="small"
                      :loading="loading"
                      icon="el-icon-user">Add/Edit Proctors
                    </el-button>
                  </el-col>
                <!-- </el-row> -->
                
                <!-- <el-row> -->
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <span>.</span>
                  </el-col>
                  <el-col v-if="examRoster" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-button
                    style="margin-top:5px;margin-bottom:5px; width:100%;"
                      type="warning"
                      @click="exportRoster()"
                      size="small"
                      :loading="loading"
                      icon="el-icon-document">Export
                    </el-button>
                  </el-col>
                <!-- </el-row> -->
                
                
                <br>
                <hr style="margin-top:50px">
                <small style="color:#9090a5">*Select assigned proctor to view / update zoom link</small>
                <el-table :data="examRoster"
                v-loading="loading"
                element-loading-text="Loading..."
                element-loading-spinner="el-icon-loading" size="small" max-height="600"
                style="margin-top: 20px;">
                  <el-table-column type="index" width="50"> </el-table-column>
                  <el-table-column prop="name" label="Proctor" width="200"></el-table-column>
                  <el-table-column label="zoom link" width="300"></el-table-column>
                  <el-table-column label="Exam Roster" width="100">
                    <template slot-scope="scope">
                      <el-button @click="handleViewRoster(scope.row)" type="text" size="small">View</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>

            </el-col>
      
          </el-col>

        </el-row>

        <el-row :gutter="20" style="padding:20px">
          
        </el-row>

        <el-dialog width="55%" :destroy-on-close="true" :visible.sync="showProctorDialog" v-if="showProctorDialog">
          <edit-proctors-component :selected_schedule="selected_schedule" @button-done="done"></edit-proctors-component>
        </el-dialog>

        <el-dialog title="EXAM ROSTER" width="95%" :destroy-on-close="true" :visible.sync="showExamRoster" v-if="showExamRoster">
          <view-roster-component :selected="selected" :selected_schedule="selected_schedule"></view-roster-component>
        </el-dialog>
    </div>
</template>

<style>

.el-table .current-row {
  background: #ff8b8b;
}

</style>

<script>

import { Schedule } from "@/api_services/schedule";
import { Applicant } from "@/api_services/applicant";
import * as moment from 'moment';
import { User } from "@/api_services/user";
import EditProctorsComponent from './EditProctorsComponent.vue';
import ViewRosterComponent from './ViewRosterComponent.vue';


export default {
  components: {
    EditProctorsComponent, ViewRosterComponent
  },
  name: 'Dashboard',
  data() {
    return {
        schedules: [],
        examRoster: [],
        searchForm: {
          date: ""
        },
        loading: false,
        showDialog: false,
        selected: null,
        selected_schedule: null,
        selected_date_time: "",
        showProctorDialog: false,
        showExamRoster: false,
        hiddenCardTemp: true,
        hiddenCard: false,
        currentRow: null,
        form: {
          id: null
        }
    }
  },
  created: function() {
    this.getSchedules();
  },
  methods: {
    getSchedules() {
      let date = moment().format('YYYY-MM-DD');
      
      Schedule.get({
        params: { from: date },
      }).then((result) => {
          this.schedules = result.data.data;
      }).catch((error) => {
          console.log(error);
      });
    },
    done(){
      this.showProctorDialog = false;
      this.showExamRoster = false;
      this.hiddenCardTemp= true;
      this.hiddenCard = false;
      this.getSchedules();
      
    },
    handleCurrentChange(val) {

      if(val){
        this.currentRow = val;
        let day = moment(val.date).format('ddd');
        let start = val.time_start.split(":")[0]+":"+val.time_start.split(":")[1];
        let end = val.time_end.split(":")[0]+':'+val.time_end.split(":")[1];
        this.selected_date_time = moment(val.date).format('MMMM D, YYYY') + " (" + day + ") " + start + " - " + end;
        this.loading = true;
        this.hiddenCardTemp = false;
        this.hiddenCard = true;
      }

      if(this.currentRow.assigned_proctor_ids !== null){
        this.getUsers();
      } else {
        this.loading = false;
        this.examRoster = null;
        console.log("no proctors assigned yet");
      }
      
    },
    getUsers() {
      User.getScheduledProctors({
        params: { schedid: this.currentRow.id },
      })
      .then((result) => {
        this.examRoster = result.data.data;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
        this.examRoster = null;
      });
    },
    onSearch(){
      
    },
    handleViewRoster(user){
      this.selected = Object.assign({}, user);
      this.selected_schedule = Object.assign({}, this.currentRow);
      // console.log(this.selected);
      this.showExamRoster = true;
    },
    handleClickProctor(){
      this.selected_schedule = Object.assign({}, this.currentRow);
      this.showProctorDialog = true;
    },
    exportRoster(){
      Applicant.exportCsvList({
        sched_id: this.currentRow.id
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'applicants_'+ this.currentRow.date +'('+this.currentRow.time_start+'-'+this.currentRow.time_end+').csv');
        document.body.appendChild(link);
        link.click();
      });
    },

  }
}
</script>


