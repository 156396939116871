<template>
    <div>            
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-divider content-position="left">Basic Information</el-divider>
            <ul style="list-style-type: none;padding-left: 5px;">
              <li style="padding: 5px;">First Name : {{ form.firstname}}</li>
              <li style="padding: 5px;">Middle Name : {{ form.middlename}}</li>
              <li style="padding: 5px;">Last Name : {{ form.lastname}}</li>
              <li style="padding: 5px;">Ext : {{ form.extension}}</li>
              <li style="padding: 5px;">Email : {{ form.email}} 
                <!-- <el-button class="el-icon-edit" size="small" :applicant=applicant circle @click="handleClickEmail(form)"/> -->
              </li>
              <li style="padding: 5px;">Sex : {{ showGender(form) }} <i v-if="(form.is_sex_pnts == 1)" style="font-size: 11px; color: red;">*Prefers not to say</i></li>
              <li style="padding: 5px;">Contact No : {{ form.contact_no}}</li>
              <li style="padding: 5px;">Birth place : {{ form.birthplace}}</li>
              <li style="padding: 5px;">Birth date : {{ form.birth_date}}</li>
              <li style="padding: 5px;">Citizenship : {{ getCitizenship(form)}}</li>
              <li style="padding: 5px;">Civil status : {{ getStatus(form)}}</li>
              <li style="padding: 5px;">Name of Spouse : {{ form.spouse_name }}</li>
              <li style="padding: 5px;">Number of children : {{ form.no_of_children }}</li>
              <li style="padding: 5px;">Religion : {{ form.religion.name }}</li>
              
            </ul>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-divider content-position="left">Home Address</el-divider>

            <ul style="list-style-type: none;padding-left: 5px;">
              <li style="padding: 5px;">Street No / Bldg : {{ form.address ? form.address.address1 : ''}}</li>
              <li style="padding: 5px;">Brgy : {{ form.address ? form.address.address2 : ''}}</li>
              <li style="padding: 5px;">Town/City :  {{ form.address.town ? form.address.town.name : ''}} </li>
              <li style="padding: 5px;">Province :  {{ form.address.town ? form.address.town.province.name : ''}} </li>
            </ul>
            
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-divider content-position="left">Other Information</el-divider>

            <ul style="list-style-type: none;padding-left: 5px;">
              
              <li style="padding: 5px;">Degree program : {{ form.course ? form.course.code + ' '+ form.course.description + ' ' + (form.course.major || '')  : '' }}</li>
              <li style="padding: 5px;">Preferred Campus : {{ form.campus }}
                <!-- <el-button class="el-icon-edit" size="small" :applicant=applicant circle @click="handleClickCourse()"/> -->
              </li>
              <li style="padding: 5px;">Grade 9 GPA : {{ form.grade_9_gpa }}</li>
              <li style="padding: 5px;">Grade 10 GPA : {{ form.grade_10_gpa }}</li>
              <li style="padding: 5px;">Grade 11 GPA : {{ form.grade_11_gpa }}</li>
              <li style="padding: 5px;">Latest Education Attainment : </li>
              <li style="padding: 5px;margin-left: 15px;color:#6f6f6f">
                {{ getHsTypeName(form.hs_type) }} GPA: {{ form.hs_type_gpa }} 
                  <el-button v-if="user.role === 'admin'" class="el-icon-edit" size="small" circle @click="handleClickGPA(form)"/>
              </li>

              <div v-if="form.strand !== null">
                <li style="padding: 5px;">SHS Strand : </li>
                <li style="padding: 5px;margin-left: 15px;color:#6f6f6f">
                  {{ form.strand.strand_code }} - {{ form.strand.strand_description }}
                </li>
              </div>

              <div v-if="form.gender === 'F'">
                <li style="padding: 5px;">First female in the family to attend college: </li>
                <li style="padding: 5px;margin-left: 15px;color:#6f6f6f">
                  {{ form.is_first_female === 'Yes' ? 'Yes': 'No'}}
                </li>
              </div>

              <div v-if="form.gender === 'M'">
                <li style="padding: 5px;">First male in the family to attend college: </li>
                <li style="padding: 5px;margin-left: 15px;color:#6f6f6f">
                  {{ form.is_first_male === 'Yes' ? 'Yes': 'No'}}
                </li>
              </div>
              
              <li style="padding: 5px;">Social Categories : </li>
              <li style="padding: 5px;margin-left: 15px;color:#6f6f6f" v-for="social in getSocialCategoriesList()" :key="social">{{ social}}</li>
              <li style="padding: 5px;">Any allergies or health problem :</li>
              <li style="padding: 5px;margin-left: 15px;color:#6f6f6f">
                {{ form.pwd_handicap || 'None' }}
              </li>
            </ul>
        </el-col>

      </el-row>
      
      <el-divider content-position="left">Guardians</el-divider>
      <ul style="list-style-type: none;padding-left: 5px;">
          <li style="padding: 5px;">
          <el-table
            :data="form.guardians"
            style="width: 100%">
              <el-table-column
                prop="fullname"
                label="Fullname"
                >
              </el-table-column>
              <el-table-column
              prop="occupation"
              label="Occupation"
              >
              </el-table-column>
              <el-table-column
              prop="monthly_income"
              label="Monthly income"
                width="220"
              >
              </el-table-column>
              <el-table-column
              prop="relation"
              label="Relation"
              >
              </el-table-column>
              <el-table-column
              prop="contact_no"
              label="Contact No"
              >
              </el-table-column>
              <el-table-column
              prop="address1"
              label="Street/Brgy"
              >
              </el-table-column>
            <el-table-column
              label="Town"
              width="320">
              <template slot-scope="scope">
                {{ scope.row.town ? scope.row.town.name+', '+scope.row.town.province.name : ''}}
              </template>
            </el-table-column>
            
          </el-table>
        </li>
      </ul>

      <el-divider content-position="left">Attended Schools</el-divider>
      
      <ul style="list-style-type: none;padding-left: 5px;margin-bottom: 20px;">
          <li style="padding: 5px;">
          <el-table
            :data="form.schools_attended"
            style="width: 100%">
              <el-table-column
                prop="date_attended"
                label="Year Graduated"
                >
                <template slot-scope="scope">
                {{  scope.row.date_attended }}
              </template>

              </el-table-column>
              <el-table-column
              label="School"
          >
              <template slot-scope="scope">
                {{ scope.row.school ? scope.row.school.name : ''}}
              </template>
            </el-table-column>
            <el-table-column
              label="Type"
              width="100">
              <template slot-scope="scope">
                {{ scope.row.school ? scope.row.school.type : ''}}
              </template>
            </el-table-column>
            <el-table-column
              label="Level"
              width="100">
              <template slot-scope="scope">
                {{ scope.row.school ? getSchoolLevel(scope.row.school.level) : ''}}
              </template>
            </el-table-column>
            <el-table-column
              label="Type"
              >
              <template slot-scope="scope">
                {{ scope.row.school && scope.row.school.town ? scope.row.school.town.name+', '+scope.row.school.town.province.name : ''}}
              </template>
            </el-table-column>
          
          </el-table>
        </li>
      </ul>

      <div v-if="show_attachment">
        <el-divider content-position="left" >Attachments</el-divider>
          <ul style="list-style-type: none;padding-left: 5px;">
            <li >
             
              <el-link style="margin: 20px;" v-for="attachment in getAttachments()" :key="attachment.id" type="primary" :href="attachment.full_path" target="_blank">
                <i class="el-icon-paperclip"></i> {{ getFormType(attachment.type) }} 
                </el-link>
            </li>
          </ul>
      </div>

          <el-dialog title="Update Email" width="40%" :visible.sync="showDialog" :destroy-on-close.sync="destroyOnClose">
            <update-email-component :selected="selected" @saved="emailSaved"></update-email-component>
          </el-dialog>

          <el-dialog title="Update Degree Program" width="40%" :visible.sync="showDialogcourse" :destroy-on-close.sync="destroyOnClose">
            <update-course-component :selected="selected" @saved="emailSaved"></update-course-component>
          </el-dialog>

          <el-dialog title="Update GPA" width="20%" v:on:close-dialog="closeDialog" :visible.sync="showDialogGPA" :destroy-on-close.sync="destroyOnClose">
            <update-gpa-component :selected="selected" @saved="emailSaved"></update-gpa-component>
          </el-dialog>

    </div>
</template>

<script>

import * as moment from 'moment';
import UpdateEmailComponent from './UpdateEmailComponent.vue';
import UpdateCourseComponent from './UpdateCourseComponent.vue';
import UpdateGpaComponent from './UpdateGpaComponent.vue';

export default {
  components: {
    UpdateEmailComponent, UpdateCourseComponent, UpdateGpaComponent
  },
  name: 'ApplicantPreview',
  props: ['form', 'show_attachment'],
  data() {
    return {
      destroyOnClose: true,
      selected: null,
      showDialog: false,
      showDialogcourse: false,
      showDialogGPA: false,
      user: this.$store.state.savedUser,
    }
  },
  created() {
    console.log(this.form);
  },
  methods: {
    emailSaved() {
      this.showDialog = false;
      this.selected = null;
      // this.getCourses();
    },
    handleClickEmail(applicant){
      this.selected = Object.assign({}, applicant);
      this.showDialog = true;
    },
    handleClickCourse(applicant){
      this.selected = Object.assign({}, applicant);
      this.showDialogcourse = true;
    },
    handleClickGPA(applicant){
      this.selected = Object.assign({}, applicant);
      this.showDialogGPA = true;
    },
    getAttachments() {
      const attachmentTypes = [];
      const attachmentMap = this.$store.state.attachment_types_map.find(attachmentType => attachmentType.hs_type === this.form.hs_type);

      attachmentMap.form_types.forEach(type => {
        attachmentTypes.push(type);
      });

      this.form.social_categories.forEach((cat, idx) => {
        if (cat === true) {
          const socialMapIndex = this.$store.state.social_categories_and_attachment_types[idx];
          attachmentTypes.push(socialMapIndex);
        }
      });

      return this.form.attachments.filter(attachment => {
        return attachmentTypes.includes(attachment.type)
      });
    },

    showGender(item) {
      let string = ""
      this.$store.state.genders.forEach(function(gender){
        if (gender.id == item.gender ) {
          string = gender.value
        }
      })

      return string
    },
    getHsTypeName(hs_type) {
        let string = ""
        this.$store.state.hs_types.forEach(function(type){
          if (type.id == hs_type ) {
            string = type.value
          }
        })  

      return string
    },
    getDate(date) {
        return moment(date).format('YYYY-MM-DD')
    },
    getSchoolLevel(level) {
      let res = ""
      this.$store.state.school_levels.forEach(function(l){
        if (l.id == level) res = l.value
      })
      return res
    },
    getFormType(type) {
      let res = ""
      this.$store.state.form_types.forEach(function(f){
        if (f.id == type) {
          res = f.value
          if (f.note) {
            res += "( "+f.note+" )"
          }
        }
      })
      return res
    },
     getCitizenship(item) {
      let res = ""
      this.$store.state.citizenships.forEach(function(c){
        if (c.id == item.citizenship) res = c.value
      })
      return res
    },
    getStatus(item) {
      let res = ""
       this.$store.state.statuses.forEach(function(s){
        if (s.id == item.civil_status) res = s.value
      })
      return res
    },
    getSocialCategoriesList() {
      let list = [];
      let self = this
      if (!this.form.social_categories) { return []; }
      this.form.social_categories.forEach(function(social,index){
        if (social) list.push(self.$store.state.social_category_names[index])
      })

      return list;
    }
  }
}
</script>