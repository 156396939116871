import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from '@/store'
import Element, { Message } from 'element-ui'
import locale from '@/services/eng_lang'
import api from "./api_services/axios";
import moment from 'moment'

import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Element, {locale})

Vue.config.productionTip = false

import BackToHomeButton from '@/components/common/BackToHomeButton.vue'
import PageNotFound from '@/components/common/PageNotFoundComponent.vue'
import AppFooterComponent from './components/common/AppFooterComponent.vue'
import VueMask from 'v-mask'
Vue.use(VueMask);
Vue.component('back-to-home', BackToHomeButton)
Vue.component('page-not-found', PageNotFound)
Vue.component('app-footer', AppFooterComponent)


api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error: any) => {
    let messages: Array<any> = []
    if (error.response && error.response.status === 401 && !router.currentRoute.meta.public && router.currentRoute.name !== 'Login') {
      router.replace({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath }
      });
    }
    if (error.response && error.response.status === 400) {
      if (error.response.data.message) {
        messages.push(error.response.data.message)
      }

      if (error.response.data.errors) {
        let errs = error.response.data.errors
       
        for(let i in errs) {
         
          errs[i].forEach((message: any) => {
            messages.push(message)
          });
        
        }
       
      }
      
    }
    
    if (error.response && error.response.status === 413) {
      messages.push('File is too large')
    }

    if (error.response && error.response.status === 500) {
      messages.push('Internal Server Error')
     
    }

    messages.forEach(function(message){
      Message({
        message: message,
        type: 'error',
        duration: 3000
      });
    })
    return Promise.reject(error.response);
  }
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
