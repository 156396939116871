<template>
    <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-card>
                    <el-button :loading="loading" style="margin-bottom: 10px;" size="small" type="success" @click="generateInitialRanking"><i class="el-icon-data-line"></i> Generate Initial Ranking and Tagging</el-button>
                    <el-collapse v-model="activeName" accordion>
                        <el-collapse-item title="VSU MAIN" name="1">
                            <el-table :data="courses" 
                            highlight-current-row
                            @current-change="handleCurrentChange"
                            size="mini"
                            stripe style="width: 100%">
                                <el-table-column prop="id" label="ID" width="70"></el-table-column>
                                <el-table-column prop="description" label="Degree Program" width="320"></el-table-column>
                                <el-table-column prop="major" label="Major"></el-table-column>
                            </el-table>
                        </el-collapse-item>
                        <el-collapse-item title="VSU TOLOSA" name="2">

                        </el-collapse-item>
                        <el-collapse-item title="VSU ISABEL" name="3">

                        </el-collapse-item>
                        <el-collapse-item title="VSU ALANGALANG" name="4">

                        </el-collapse-item>
                        <el-collapse-item title="VSU VILLABA" name="4">

                        </el-collapse-item> 
                    </el-collapse>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-button style="margin-bottom: 10px;" size="mini" type="warning" @click="generateInitialRanking"><i class="el-icon-plus"></i> Add Tagging</el-button>
                <el-tabs type="border-card">
                    <el-tab-pane>
                        <span slot="label"><i class="el-icon-date"></i> Program Degree Ranking</span> 
                        <el-table :data="applicants" stripe style="width: 100%" size="small">
                            <el-table-column prop="id" label="ECN" width="60"></el-table-column>
                            <el-table-column prop="firstname" label="First Name"></el-table-column>
                            <el-table-column prop="middlename" label="Middle Name"></el-table-column>
                            <el-table-column prop="lastname" label="Last Name"></el-table-column>
                            <el-table-column prop="email" label="Campus Rank" ></el-table-column>
                            <el-table-column prop="contact_no" label="Course Rank"></el-table-column>
                            <el-table-column prop="contact_no" label="Tag"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Qualifier">
                        <el-transfer
                            filterable
                            :filter-method="filterMethod"
                            filter-placeholder="Search Lastname"
                            v-model="value"
                            :titles="['Non-Qualifier', 'Qualifier']"
                            :data="data">
                            <el-button class="transfer-footer" slot="right-footer" size="small">Save</el-button>
                        </el-transfer>
                    </el-tab-pane>
                    <el-tab-pane label="Accepted">
                        <el-transfer
                            filterable
                            :filter-method="filterMethod"
                            filter-placeholder="Search Lastname"
                            v-model="value"
                            :titles="['Non-Qualifier', 'Accepted']"
                            :data="data">
                            <el-button class="transfer-footer" slot="right-footer" size="small">Save</el-button>
                        </el-transfer>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
            
        
    </div>
    
  </template>

  <script>
  import { Course } from "@/api_services/course";

  export default {
    data() {
      const generateData = _ => {
        const data = [];
        const applicants = ['Garcia, Elvin', 'Mayorca, Mercy Faith Denolan', 'Miras, Hazel Yparraguirre', 'Billones, Franz Borinaga', 'Parrilla, Joan Nes Bañez', 'Padecio, Jhane Siano'];
        const initials = ['Garcia', 'Mayorca', 'Miras', 'Billones', 'Parrilla', 'Padecio'];
        applicants.forEach((city, index) => {
          data.push({
            label: city,
            key: index,
            initial: initials[index]
          });
        });
        return data;
      };
      return {
        data: generateData(),
        value: [],
        filterMethod(query, item) {
          return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },
        destroyOnClose: true,
        loading: false,
        searchForm: {},
        courses: [],
        meta: {
            page: 1,
            pages: 1,
            total: 211,
            per_page: 10,
        },
        selected: null,
        showDialog: false,
        activeName: '1'
      }
    },
    created() {
        this.onSearch();
    },
    methods: {
        handleClick(course) {
            this.selected = Object.assign({}, course);
            this.showDialog = true;
        },
        deleteCourse(course) {
        if (confirm('Are you sure you want to delete this Degree program?')) {

            Course.delete(course.id, {}).then((response) => {
            this.getCourses();
            }).catch((error) => {
            if (error.response.data.message) {
                this.$message({
                message: error.response.data.message,
                type: "error",
                duration: 3000,
                });
            }
            });
        }
        },
        addForm() {
            this.showDialog = true;
            this.selected = null;
        },
        courseSaved() {
            this.showDialog = false;
            this.selected = null;
            this.getCourses();
        },
        onSearch() {
            this.meta.page = 1;
            this.loading = true;
            this.getCourses();
        },
        pageChanged(val) {
            this.meta.page = val;
            this.getCourses();
        },
        getCourses() {
            Course.get({
                params: { page: this.meta.page, term: this.searchForm.name },
            })
                .then((result) => {
                this.courses = result.data.data;
                this.meta = {
                    page: result.data.meta.current_page,
                    total: result.data.meta.total,
                    per_page: result.data.meta.per_page,
                    pages: result.data.meta.last_page,
                };
                this.loading = false;
                // console.log(this.courses);
                })
                .catch((error) => {
                this.loading = false;
                this.applicants = [];
                });
        },
        handleCurrentChange(){

        },
        generateInitialRanking(){
            this.loading = true;
        }
    }
  };
</script>