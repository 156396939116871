<template>
  <el-form ref="form" :model="form"  :rules="rules" style="padding-left: 15px; padding-right: 15px;background: #ebeef5">
      <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
              <el-form-item label="School name" prop="school" class="input-full-width">
                  <search-school-component :school.sync="form.school" @change="changeSchool($event)"></search-school-component>
              </el-form-item>
          </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="Town" prop="town">
                  <search-town-component :town.sync="form.town" style="width:100%" @change="changeTown($event)" :disabled="hasSchoolSaved"></search-town-component>
              </el-form-item>
              
          </el-col>
          
      </el-row>
      <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Year Graduated" prop="date_attended" class="input-full-width">
                  <el-date-picker type="year" placeholder="Pick a Year" format="yyyy" value-format="yyyy" v-model="form.date_attended" style="width: 100%;"></el-date-picker>
              </el-form-item>
          </el-col>
          
          
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Type" prop="type" class="input-full-width">
                  <el-select v-model="form.type" placeholder="Type" size="large" style="width: 100%;" :disabled="hasSchoolSaved">
                      <el-option :label="type.value" :value="type.id" v-for="type in $store.state.school_types" :key="type.id"></el-option>
                  </el-select>
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Level" prop="level" class="input-full-width">
                  <el-select v-model="form.level" placeholder="Type" size="large" style="width: 100%;" :disabled="hasSchoolSaved">
                      <el-option :label="level.value" :value="level.id" v-for="level in $store.state.school_levels" :key="level.id"></el-option>
                  </el-select>
              </el-form-item>
          </el-col>
          
      </el-row>
  </el-form>
</template>

<script>

import SearchTownComponent from '@/components/common/SearchTownComponent.vue'
import SearchSchoolComponent from '../SearchSchoolComponent.vue'

export default {
  name: 'SchoolAttendedForm',
  props: ['school_attended'],
  components:{ SearchTownComponent, SearchSchoolComponent },
  data() {
    return {
        form: this.school_attended,
        rules: {
            date_attended: [
                { required: true, message: 'Date attended is required', trigger: 'blur' },
            ],
            school: [
                { required: true, message: 'School name is required', trigger: 'blur' },
            ],
            type: [
                { required: true, message: 'School type is required', trigger: 'blur' },
            ],
            level: [
                { required: true, message: 'School level is required', trigger: 'blur'  },
            ],
            town: [
                { required: true, message: 'Town or City is required', trigger: 'blur' },
            ]
        }
    }
  },
  watch:{
        school_attended: {
            deep: true,
            handler() {
               this.validateForm(this.form)
            }
        }
  },
  created: function() {
      this.form.valid = false
      this.$emit('update:school_attended', this.form)
      this.validateForm(this.form)
  },
  computed: {
      hasSchoolSaved() {
          if (this.form.school && !this.form.school.name) {
               console.log('no school name')
              return true
          } 
          if (this.form.school && this.form.school.id) {
              console.log('has school id')
              return true
          }

          return false
      },
  },
  methods: {
      changeTown(town) {
        // if (town) {
        //     this.form.town = town
        //     if (this.form.school) this.form.school.town = town
        // }
      },
      changeSchool(school) {
        
          if (school) {
            let form = {
                school: school,
                level: school.level,
                type: school.type,
                town: school.town
            }
          
            this.validateForm(form)
          }
        //  if (school) {
        //      if (!school.id) {
        //          this.form = {
        //              school: school,
        //              level: null,
        //              type: null,
        //              town: null
        //          }
        //      } else {
        //          this.form = {
        //              school: school,
        //              level: school.level,
        //              type: school.type,
        //              town: school.town
        //          }
        //      }
        // }
        // this.validateForm()
      },
    timeOut(time) {
        return new Promise(resolve => {
            setTimeout(resolve, time);
        });
    },
    async validateForm(form) {
        await this.timeOut(100);
        form.valid = false
        this.$emit('update:school_attended', form)
        const valid = await this.$refs.form.validate();
        form.valid = valid
        this.$emit('update:school_attended', form)
    },
  }
}
</script>