<template>
    <div >
      <el-form ref="form" :model="form" :rules="rules" @submit.native.prevent="onSubmit()">
          <el-divider content-position="left">Basic Info</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="First Name" prop="firstname">
                <el-input v-if="false" v-model="form.revert_ctr" placeholder="revert counter " size="large"></el-input>
                <el-input v-model="form.firstname" @input="(val) => (form.firstname = form.firstname.charAt(0).toUpperCase() + form.firstname.slice(1))" placeholder="First name" size="large"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="5">
              <el-form-item label="Middle Name" prop="middlename">
                <el-input v-model="form.middlename" @input="(val) => (form.middlename = form.middlename.charAt(0).toUpperCase() + form.middlename.slice(1))" placeholder="Middle Name" size="large"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="Last Name" prop="lastname">
                <el-input v-model="form.lastname" @input="(val) => (form.lastname = form.lastname.charAt(0).toUpperCase() + form.lastname.slice(1))" placeholder="Last Name" size="large"></el-input>
              </el-form-item>
            </el-col>
             <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
              <el-form-item label="Extension" prop="extension">
                <el-input v-model="form.extension" placeholder="Ex. Jr , III " size="large"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

           <el-row :gutter="20">
           

            <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
               <el-form-item label="Email">
                <el-input v-model="form.email" size="large" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <el-form-item label="Contact No" prop="contact_no">
                  <el-input v-model="form.contact_no" placeholder="09XXXXXXXXX" v-mask="'###########'" size="large"></el-input>
                </el-form-item>
              </el-col>
          </el-row>

          <el-row :gutter="20">
              
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                 <el-form-item label="Birth Place" prop="birthplace">
                  <el-input v-model="form.birthplace" placeholder="Birth Place" size="large"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <el-form-item label="Birth Date" prop="birth_date">
                  <el-date-picker type="date" placeholder="Pick a date" v-model="form.birth_date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <el-form-item label="Sex" prop="gender" style="margin-bottom: 0px">
                  <el-select v-model="form.gender" placeholder="Sex" size="large" style="width: 100%;">
                    <el-option :label="gender.value" :value="gender.id" v-for="gender in $store.state.genders" :key="gender.id"></el-option>
                  </el-select>    
                </el-form-item>
                <el-form-item prop="is_sex_pnts">
                  <el-checkbox v-model="form.is_sex_pnts" ><i style="font-size: 11px">Please check if you prefer not to say it.</i>
                  </el-checkbox>  
                </el-form-item>
              </el-col>
          </el-row>

          <el-row :gutter="20">
              
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <el-form-item label="Civil Status" prop="civil_status">
                  <el-select v-model="form.civil_status" placeholder="Civil Status" size="large" style="width: 100%;">
                    <el-option :label="status.value" :value="status.id" v-for="status in $store.state.statuses" :key="status.id"></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <el-form-item label="Citizenship" prop="citizenship">
                    <el-select v-model="form.citizenship" placeholder="Citizenship" size="large" style="width: 100%;">
                      <el-option :label="citizenship.value" :value="citizenship.id" v-for="citizenship in $store.state.citizenships" :key="citizenship.id"></el-option>
                    </el-select>
                  </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <el-form-item label="Religion" prop="religion">
                      <el-select filterable v-model="form.religion" value-key="id"  placeholder="Religion" size="large" class="religion-select" style="width: 100%">
                        <el-option :label="religion.name" :value="religion" v-for="religion in religions" :key="religion.id"></el-option>
                      </el-select>
                  </el-form-item>
              </el-col>
          </el-row>
          
          <el-row :gutter="20" v-if="form.civil_status == 2">
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <el-form-item label="Name of Spouse" prop="spouse_name">
                <el-input v-model="form.spouse_name" placeholder="Name of Spouse" size="large"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
                 <el-form-item label="Number of children" prop="no_of_children">
                  <el-input v-model="form.no_of_children" placeholder="No. of children" size="large"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
           <el-button native-type="submit" type="primary" @click="onSubmit()" style="display:none">Submit</el-button>
      </el-form>
    </div>
</template>

<script>

import { Religion } from '@/api_services/religion'


export default {
  name: 'Step1',
  props: ['form','step', 'check', 'save_counter', 'save_valid'],
  data() {
    const validateContact = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Contact no is required'));
        return;
      }

      if (value.length < 11) {
        callback(new Error('Invalid Contact number'));
        return;
      }

      if (value.substr(0, 2) !== '09') {
        callback(new Error('Invalid Contact number'));
        return;
      }

      callback();
    };
    const validateBirthYearPicked = (rule, value, callback) =>{

      if (!value) {
        callback(new Error('Birth date is required'));
        return;
      }

      if (value >= "2011-01-01") {
        callback(new Error("Please review birth date"));
        return;
      }

      callback();
    };

    return {
      applicant: {},
      religions: [],
      religion_id: null,
      is_sex_pnts: null,
      testinput: null,
      rules: {
        firstname: [
          { required: true, message: 'First name is required', trigger: 'blur' },
        ],
        lastname: [
          { required: true, message: 'Last name is required', trigger: 'blur' },
        ],
        contact_no: [
          { trigger: 'blur', validator: validateContact },
        ],
        address1: [
          { required: true, message: 'Address is required', trigger: 'blur' },
        ],
        address2: [
          { required: true, message: 'Barangay is required', trigger: 'blur' },
        ],
        zipcode: [
          { required: true, message: 'Zipcode is required', trigger: 'blur' },
        ],
        birthplace: [
          { required: true, message: 'Birth place is required', trigger: 'blur' },
        ],
        birth_date: [
          { trigger: 'blur' , validator: validateBirthYearPicked},
        ],
        gender: [
          { required: true, message: 'Sex is required', trigger: 'blur' },
        ],
        civil_status: [
          { required: true, message: 'Civil status is required', trigger: 'blur' },
        ],
        citizenship: [
          { required: true, message: 'Citizenship is required', trigger: 'blur' },
        ],
        religion: [
          { required: true, message: 'Religion is required', trigger: 'blur' },
        ],
        spouse_name: [
          { required: true, message: 'Name of Spouse is required', trigger: 'blur' },
        ],
        no_of_children: [
          { required: true, message: 'Spouse Name is required', trigger: 'blur' },
        ]
      }
    }
  },
  watch: {
    'form.religion': function() {
      this.form.religion_id = this.form.religion.id;
    },
    check: function() {
      this.checkForm()
    },
    save_counter: function() {
      this.checkForSave()
    }
  },
  created: function() {
    this.getReligions()
    console.log(this.step)
  }, 
  methods: {
    setReligions(religions) {
      this.religions = religions;

      this.religions.forEach(religion => {
        if (religion.name.length > 30) {
          religion.name = religion.name.slice(0, -(religion.name.length - 30)) + '...';
        }
      });

      if (this.form.religion) {
        this.form.religion_id = this.form.religion.id;
      }
    },
    getReligions() {

      if (this.$store.state.religions.length) {
        this.setReligions(this.$store.state.religions);
      } else {
        Religion.get({
          params: { page: 1, per_page: 999 },
        }).then(result => {
          if (result) {
            this.$store.state.religions = result.data.data;
            this.setReligions(result.data.data);
          } 
        }).catch( error => {
          console.error(error)
        })
      }
    },
    onSubmit() {
      this.checkForm()
    },
    async checkForm() {
      const valid = await this.$refs.form.validate();
      if (!valid) return
      this.$emit('update:step', 1)
    },
    async checkForSave() {
      this.$emit('update:save_valid', false)
      const valid = await this.$refs.form.validate();
      if (!valid) return
      this.$emit('update:save_valid', true)
    }
  }
}
</script>
