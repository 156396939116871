import api from './api'

const API = '/api/attachments'

export const Attachment = {
  get: function (params) {
    return api.get(API, params)
  },
  upload: function(params) {
    return api.createFormData(API, params)
  },
  delete: function (id, params) {
    return api.delete(API + '/' + id, params)
  },
}
