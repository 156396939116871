<template>
    <div style="padding: 20px">
        <h4>Please review your application form</h4>
        <applicant-preview-component :form="form" :show_attachment="true"></applicant-preview-component>
    </div>
</template>

<script>
import ApplicantPreviewComponent from '@/components/applicants/ApplicantPreviewComponent.vue'

export default {
  components: { ApplicantPreviewComponent },
  name: 'Step4',
  props: ['form','step', 'check'],
  data() {
    return {
     
    }
  },
  watch: {
    check: function() {
      this.doneReview()
    }
  },
  methods: {
    doneReview() {
      this.$emit('update:step', 5)
    }
  }
}
</script>
