import axios from 'axios'

const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization' : null
  }

export default axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
    // baseURL: "http://127.0.0.1:8000",
  withCredentials: false,
  headers: HEADERS
})