<template>
<div>
  <el-row :gutter="20">       
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <h3>{{ type.value }}</h3>
      <p>{{ type.note }}</p>
    </el-col>

    <el-col>
      <div>
        <el-upload
          :multiple="true"
          style="width:100%"
          class="upload-demo"
          ref="upload"
          action=""
          :limit="3"
          :on-change="handleChange" 
          :file-list="attachments"
          accept="image/png, image/jpeg, application/pdf"
          :on-remove="deleteAttachment"
          :auto-upload="false">

          <el-button slot="trigger" size="small" type="primary" v-if="attachments.length < 3">select file</el-button>
<!--     
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button> -->

          <div class="el-upload__tip" slot="tip">Only {{ types }} are allowed file types</div>
        </el-upload>
          <!-- <el-link  type="primary" :href="form.full_path" v-if="form.url" target="_blank">View Attachment</el-link> -->
      </div>
    </el-col>
  </el-row>

  <div class="attachment-container">
    <div class="block" v-for="attachment in attachments" :key="attachment.id">
      <span class="filename">{{ attachment.filename }}</span>
      <el-image :src="attachment.full_path">
        <div slot="placeholder" class="image-slot">
          Loading<span class="dot">...</span>
        </div>
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <el-button type="danger" icon="el-icon-delete" @click="deleteAttachment(attachment)" circle></el-button>
    </div>
      <!-- <el-link  type="primary" :href="attachment.url" target="_blank">View Attachment</el-link> -->
  </div>
</div>
</template>

<script>


import { Attachment } from '@/api_services/attachment'

export const HS_FORM_TYPES = [
  {hs_type: 1, form_types: [1, 2, 3]},
  {hs_type: 2, form_types: [1, 5]},
  {hs_type: 3, form_types: [1, 6, 7]},
  {hs_type: 4, form_types: [1, 6, 7]},
  {hs_type: 5, form_types: [1, 10]}
]

export default {
  name: 'AttachmentFormType',
  props: ['applicant', 'attachment_update', 'type'],
  data() {
    return {
      allowed_types: ['image/png', 'application/pdf', 'image/jpeg'],
      types: 'png, jpeg, pdf',
      attachments: [],
      fileList: [],
      file: null,
    }
  },
  watch:{
    attachment: {
      deep: true,
      handler() {
        // this.validateForm()
      }
    },
    applicant: {
      deep: true,
      handler() {
        this.attachments = this.applicant.attachments.filter((attachment) => {
          return parseInt(attachment.type) === parseInt(this.type.id);
        }) || [];

        this.attachments.forEach(attachment => {
          attachment.name = attachment.filename
          attachment.url = attachment.full_path
        });
      }
    }
  },
  created: function() {
      if (this.type) {
        this.attachments = this.applicant.attachments.filter((attachment) => {
          return parseInt(attachment.type) === parseInt(this.type.id);
        })

        this.attachments.forEach(attachment => {
          attachment.name = attachment.filename
        });
      }
  },
  methods: {
    async handleChange(evt) {
      this.file = evt.raw
      // this.form.attachment = evt.raw 
      this.submitUpload()
    },
    getFormData() {
      const formData = new FormData();
      formData.append('attachment', this.file)
      formData.append("type", this.type.id);
      formData.append("applicant_id", this.applicant.id);
      return formData;
    },
    async submitUpload() {
      Attachment.upload(this.getFormData())
        .then(result=> {
           // this.attachments.push(result.data.data);
            this.applicant.attachments.push(result.data.data)
          this.$emit('update:attachment_update', this.attachment_update+1)
        }).catch(error => {
          console.error(error)
          this.$message({
              message: 'Unable to upload the file. Make sure that the file size is up to 8mb only',
              type: 'error',
              duration: 5000
            }); 
        })
    },
    delete(file) {
      const attachment = this.attachments.find(a => a.name === file.name);

      if (attachment) {
        this.deleteAttachment(attachment);
      }
    },
    deleteAttachment(attachment) {
      Attachment.delete(attachment.id, {})
        .then(result=> {
          const idx = this.applicant.attachments.findIndex(a => a.id === attachment.id);

          if (idx !== -1) {
            this.applicant.attachments.splice(idx, 1);
          }

          this.$message({
            message: 'Attchment removed',
            type: 'success',
            duration: 5000
          }); 
          //   this.$emit('update:attachment_update', this.attachment_update+1)
        }).catch(error => {
          console.log(error)
          this.$message({
              message: 'Unable to delete attachment',
              type: 'error',
              duration: 5000
            }); 
        })
    },
  }
}
</script>


<style scoped>
  .attachment-form {
    background: #f3fffb;
    padding: 5px;
    border-radius: 15px;
    min-height: 200px
  }
  .el-form-item__content div{
    line-height: 20px !important;
  }

  .attachment-container {
    margin-top: 1rem;
  }

  .block {
    height: 100px;
    width: 100px;
    background: #ccc;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    display: inline-block;
  }

  .block .filename {
    font-size: 10px;
    margin-bottom: 1rem;    
  }

  .block .el-image {
    width: 100%;
    height: 50px;
  }

  .attachments {
    margin: 1rem;
    
  }
</style>