<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://www.vsu.edu.ph"> Visayas State University </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; 2023 | Powered by CumulusOne
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  name: "AppFooter",
  data() {
    return {};
  },
};
</script>

<style>
footer {
  text-align: center;
  position: absolute;
  bottom: 0rem;
  width: 100%;
  margin-bottom: 1rem;
}

.container-fluid {
  display: flex;
  flex-direction: column;
}

.container-fluid ul {
  padding: 0;
  list-style: none;
}
</style>