<template>
  <div>
    <guest-applicant-component :guest.sync="guest" v-if="guest"></guest-applicant-component>
    <div class="login" v-if="!guest">
      <el-card >
        <div>
          <img :src="require('@/assets/images/vsu_logo_green.png')"  style="margin-bottom:20px;width: 100%;">
        </div>
        <div >
          <h2 style="text-align: center;">VSU Admission Guest </h2>
          <el-form
            class="login-form"
            :model="form"
            :rules="rules"
            ref="form"
            @submit.native.prevent="login"
          >
            <el-form-item prop="email">
              <el-input v-model="form.email" placeholder="Email" prefix-icon="fas fa-user"></el-input>
            </el-form-item>
            <el-form-item prop="token">
              <el-input
                v-model="form.token"
                placeholder="Token"
                type="text"
                prefix-icon="fas fa-lock"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                :loading="loading"
                class="login-button"
                type="primary"
                native-type="submit"
                block
              >Login</el-button>
            </el-form-item>
            <!-- <a class="forgot-password" href="login">Forgot token ?</a> -->
          </el-form>
        </div>
        <back-to-home />
      </el-card>
    </div>
  </div>
</template>

<script>

import { Applicant } from '@/api_services/applicant'
import store from '@/store/index'
import GuestApplicantComponent from '@/components/guest/GuestApplicantComponent.vue';

export default {
  components: { GuestApplicantComponent },
  name: 'Login',
  data() {
    return {
      guest: null,
      form: {
        email: "",
        token: ""
      },
      loading: false,
      rules: {
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur"
          }
        ],
        token: [
          { required: true, message: "Token is required", trigger: "blur" }
        ]
      }
    };
  },
  created: function() {
    this.guest = this.$store.state.guest
    console.log(this.guest)
  },
  methods: {
    timeOut() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    },
    async login() {
        this.loading = true
        const valid = await this.$refs.form.validate();
      
        if (!valid) return;
        
        Applicant.verifyToken(this.form)
        .then(result => {
          console.log(result.data.data)
          this.loading = false
          const guest = result.data.data;
          guest['token'] = this.form.token;
          store.commit('saveGuest', guest)
          this.guest = this.$store.state.guest
        })
        .catch( error => {
          this.loading = false
          if (error.status === 401) {
            this.$message({
              message: "Invalid credential",
              type: 'error',
              duration: 3000
            });
          }
        })
    },
  
    async redirect() {
      await this.timeOut();
      this.loading = false;
      // this.$router.push('Main')
    }
  }
}
</script>

<style scoped>
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.login-button {
  width: 100%;
  margin-top: 40px;
}
.login-form {
  width: 290px;
}
.forgot-password {
  margin-top: 10px;
}
</style>

<style lang="scss">
$teal: rgb(0, 124, 137);
.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }
}
.login .el-input__inner:hover {
  border-color: $teal;
}
.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;
  .el-input__icon {
    width: 30px;
  }
}
.login .el-input input {
  padding-left: 35px;
}
.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}
h2 {
  font-family: "Open Sans";
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
}
a {
  color: $teal;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}
.login .el-card {
  width: 340px;
  display: flex;
  justify-content: center;
}
</style>


