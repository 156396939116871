<template>
     <div>
       <h2>{{ message || 'Page not found' }}</h2>
    </div>
</template>

<script>

export default {
  name: 'PageNotFoundComponent',
  props: ['message'],
  computed: {
    customMessage() {
      return this.message ? this.message : 'Page found'
    }
  }
}
</script>