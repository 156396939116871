<template>
    <div>
        <el-menu
            default-active="0"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="#003300"
            text-color="#fff"
            active-text-color="#fff">
            <el-menu-item v-if="secured"> <a href="/main" >
                <img :src="require('@/assets/images/vsu_logo.png')" style="" class="vsu-logo">
            </a></el-menu-item>
            <el-menu-item v-if="!secured"> <a href="/" >
                <img :src="require('@/assets/images/vsu_logo.png')" style="" class="vsu-logo">
            </a></el-menu-item>
            <el-menu-item @click="drawer = true" v-if="secured">
                <i class="el-icon-menu"></i>
                <span>Menu</span>
            </el-menu-item>
            
           

            <el-menu-item  class="right" v-if="!secured">
                <a href="login" >Login</a>
            </el-menu-item>

             <el-menu-item  class="right" v-if="!secured">
                <a href="guest" >Applicant</a>
            </el-menu-item>
            
            <el-menu-item  class="right" v-if="secured && user"  @click="showUserMenu = true ">
                 <a >
                     {{ user.name }} <span style="    color: #fbcc00;font-size:10px">-{{ user.role }} {{ user.role !== 'admin' ? user.campus : '' }}</span>
                </a>
                  
            </el-menu-item>
        </el-menu>
        <el-drawer
            :title="getTitle()"
            :visible.sync="showUserMenu"
            direction="rtl" size="150px">
            <div style="padding: 20px; text-align:center">
                <el-link type="primary" href="/profile"> <i class="el-icon-user-solid"></i> Profile</el-link>
                <el-divider></el-divider>
                <el-link type="primary" href="/logout"> <i class="el-icon-error"></i> Logout</el-link>
            </div>
            </el-drawer>

        <sidebar-component :drawer.sync="drawer" @update="closeDrawer()"></sidebar-component>
    </div>
</template>

<script>

import SidebarComponent from '@/components/common/SidebarComponent.vue'


export default {
  name: 'Navbar',
  props:['secured'],
  data() {
    return {
        showUserMenu: false,
        activeIndex: '1',
        activeIndex2: '2',
        drawer: false
    }
  },
  components: { SidebarComponent },
  computed: {
    user () {
      let user = this.$store.state.savedUser
      return user
    },
  },
  methods: {
      getTitle() {
          if (this.user) return this.user.name
          return ""
      },
      closeDrawer() {
            this.drawer = false
            this.activeIndex = '0'
      },
    handleSelect(key, keyPath) {
        this.activeIndex = '0'
      }
  }
}
</script>
