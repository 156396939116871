import api from './api'

const API = '/api/schools'

export const School = {
  get: function (params) {
    return api.get(API, params)
  },
  create: function(data) {
    return api.create(API, data);
  }
}
