<template>
    <div class="Proctors">
        <el-form
        :model="form"
        ref="form">

        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
              <el-divider content-position="left">Proctor Information</el-divider>
              <ul style="list-style-type: none;padding-left: 5px;">
                <li style="padding: 2px;">Proctor: <b>{{ proctor.name }}</b></li>
                <li style="padding: 2px;">Email: <b>{{ proctor.email }}</b></li>
                <li style="padding: 2px;">Zoom link: <b>{{ roster.zoom_link }}</b></li>
                <li>
                  <el-input placeholder="Input Zoom link here" type="text" v-model="form.zoom_link" style="width: 60%" size="small">
                    <el-button type="success" slot="append" @click="updateLink" icon="el-icon-check"></el-button>
                  </el-input>
                </li>
                
              </ul>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <el-divider content-position="left">Exam Schedule Details</el-divider>
            <ul style="list-style-type: none;padding-left: 5px;">
              <li style="padding: 2px;">Date: <b>{{ dayy + " (" + day + ") " }}</b></li>
              <li style="padding: 2px;">start: <b>{{ start }}</b></li>
              <li style="padding: 2px;">end: <b>{{ end }}</b></li>
              <li>
                <el-button
                  type="warning"
                  @click="exportRoster(schedule)"
                  size="small"
                  :loading="loading"
                  icon="el-icon-document">Export
                </el-button>
                <el-button
                  type="success"
                  @click="printRoster"
                  size="small"
                  :loading="loading"
                  icon="el-icon-printer">Print
                </el-button>
              </li>
            </ul>
          </el-col>
          
        </el-row>

        <el-card style="margin-top: 5px">
          <el-table 
          ref="multipleTable"
          :data="rosterReport"
          v-loading="loading"
          element-loading-text="Loading..."
          element-loading-spinner="el-icon-loading"
          size="small" max-height="300">
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="control_no" label="EXAMINEE CONTROL NUMBER (ECN)"></el-table-column>
            <el-table-column  label="NAME (LAST NAME, FIRST NAME)">
              <template slot-scope="scope" >
                {{ (scope.row.lastname + ", " + scope.row.firstname + " " + scope.row.middlename  ).toUpperCase() }} {{ !scope.row.extension ? " " : scope.row.extension }} 
              </template>
              
            </el-table-column>
            <el-table-column prop="email" label="EMAIL"></el-table-column>
            <el-table-column label="BIRTHDAY">
              <template slot-scope="scope" >
                {{ scope.row.birth_date }}  
              </template>
            </el-table-column>
  
          </el-table>
        </el-card>

      </el-form>
        
    </div>
  </template>
  
  <script>
  
  import { User } from "@/api_services/user"; 
  import { Schedule } from "@/api_services/schedule";
  import { ExamRoster } from "@/api_services/examroster";
  import { ExamRosterReport } from "@/api_services/examrosterreport";
  import { Applicant } from "@/api_services/applicant";
  import * as moment from 'moment';

  export default {
    name: "ViewRosterComponent",
    props: ["selected", "selected_schedule"],

  
    data() {
      return {
        roster: [],
        rosterReport: [],
        loading: false,
        form: {
          zoom_link: '',
          zoom_meeting_id: '',
          zoom_passcode: ''
        },
        proctor: null,
        schedule: null,
        day: "",
        dayy: "",
        start: "",
        end: "",
      };
    },
    created() {
      // console.log(this.selected);
      // console.log(this.selected_schedule);
      this.proctor = this.selected,
      this.schedule = this.selected_schedule,
      this.day = moment(this.schedule.date).format('ddd');
      this.start = this.schedule.time_start.split(":")[0]+":"+this.schedule.time_start.split(":")[1];
      this.end = this.schedule.time_end.split(":")[0]+':'+this.schedule.time_end.split(":")[1];
      this.dayy = this.selected_date_time = moment(this.schedule.date).format('MMMM D, YYYY');
      this.getExamRoster();
      this.getExamRosterReport();
    },
    methods: {
      updateLink(){
        this.form.proctor_id = this.proctor.id;
        this.form.schedule_id = this.schedule.id;
        // console.log(this.form);
        ExamRoster.update(this.roster.id, this.form)
        .then(response => {
          // console.log(response.data);
          this.roster = response.data.data;
          this.$message({
            message: 'Zoom Link Saved',
            type: 'success'
          });
        }).catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
              duration: 3000
            });
          }
        });
      },
      getExamRoster(){
        this.loading = true;
        console.log(" proctorid: " + this.proctor.id + " scheduleid: " + this.schedule.id);
        ExamRoster.get({
          params: { scheduleid: this.schedule.id, proctorid: this.proctor.id, key: '1'},
        }).then((result) => {
          this.roster = result.data.data[0];
          console.log(this.roster.id + " here");
        }).catch((error) => {
          console.log(error);
        });
      },
      getExamRosterReport(){
        ExamRosterReport.get({
          params: { scheduleid: this.schedule.id, proctorid: this.proctor.id, key: '1'},
        }).then((result) => {
          this.rosterReport = result.data.data;
          console.log(this.rosterReport);
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      },
      exportRoster(Schedule){
        Applicant.exportCsvList({
          sched_id: Schedule.id
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'applicants_'+ Schedule.date +'('+Schedule.time_start+'-'+Schedule.time_end+').csv');
          document.body.appendChild(link);
          link.click();
        });
      },
      printRoster(){
        ExamRosterReport.print({
          params: { scheduleid: this.schedule.id, proctorid: this.proctor.id },
        }).then((result) => {
          this.rosterReport = result.data.data;
          console.log(this.rosterReport);
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      }
      
    },
  };
  </script>
  