<template>
    <div class="Email">
      <el-form
      :model="form"
        ref="form"
        label-width="50px"
      >
        <el-form-item label="GPA" prop="email">
          <el-input type="number" v-model="form.hs_type_gpa" style="width: 100%;"></el-input>
        </el-form-item>
  
        <el-form-item>
          <el-button type="primary" @click="update" style="width: 100%;">Update</el-button>
          <!-- <el-button @click="resetForm">Reset</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  import { Applicant } from '@/api_services/applicant'
  export default {
    name: "UpdateGpaComponent",
    props: ["selected"],

  
    data() {
      return {
        form: this.selected,
      };
    },
    created() {
      this.form = this.selected;
      // console.log(this.form);
    },
    methods: {
      async submitForm() {
        // console.log("test");
      },
      update() {
        this.$confirm('Are you sure you want to update the GPA of ' + this.form.firstname + ' ' + this.form.lastname +' . Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          Applicant.updateGpa(this.form)
            .then((response) => {
              this.$emit("saved", response.data.data);
              this.$message({
                type: 'success',
                message: 'GPA updated'
              });
              this.$router.go(0);
            }).catch((error) => {
              this.$message({
                message: error.response.data.message,
                type: "error",
                duration: 3000,
              });
            });
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'canceled'
          });          
        });
      }
    },
  };
  </script>
  