<template>
     <router-view/>
</template>

<script>

export default {
  name: 'ApplicantsHome',
  data() {
    return {
        
    }
  }
}
</script>