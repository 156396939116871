import api from './api'

const API = '/api/applicants'

export const Applicant = {
    analytics: function(params) {
        return api.get(API + '/analytics', params)
    },
    storeEmail: function(params) {
        return api.create(API + '/store-email', params)
    },
    verifyToken: function(params) {
        return api.create(API + '/verify-token', params)
    },
    completeApplication: function(id, params) {
        return api.create(API + '/complete/'+id, params)
    },
    updateSchedule: function(id, params) {
        return api.create(API + '/update-schedule/'+id, params)
    },
    revertApplication: function(id, params) {
        return api.create(API + '/revert-application/'+id, params)
    },
    rejectApplication: function(id, params) {
        return api.create(API + '/reject-application/'+id, params)
    },
    updateExamResult: function(id, params) {
        return api.create(API + '/update-exam-result/'+id, params)
    },
    updateDetails: function(params) {
        return api.create(API + '/update-details/'+params.id, params)
    },
    get: function(params) {
        return api.get(API, params)
    },
    create: function(params) {
        return api.create(API, params)
    },
    show: function(id, params) {
        return api.get(API + '/' + id, params)
    },
    update: function(id, params) {
        return api.update(API+'/'+id, params)
    },
    lock: function(id) {
        return api.create(`${API}/lock/${id}`, null);
    },
    unLock: function(id) {
        return api.create(`${API}/un-lock/${id}`, null);
    },
    getBy: function(id, params) {
        return api.get(API + '/id/' + id, params)
    },
    exportZip: function(params) {
        return api.download(API + '/export-zip', params)
    },
    exportCsv: function(params) {
        return api.download(API + '/export', params)
    },
    exportCsvList: function(params) {
        return api.download(API + '/export-list', params)
    },
    getList: function(params) {
        return api.get(API + '/get-list', params)
    },
    importres: function(params) {
        return api.create(API + '/import-res', params)
    },
    importresfinal: function(params) {
        return api.create(API + '/import-res-final', params)
    },
    getResults: function(params) {
        return api.get(API + '/get-results', params)
    },
    updateEmail: function(params) {
        return api.create(API + '/update-email', params)
    },
    updateGpa: function(params) {
        return api.create(API + '/update-gpa', params)
    },
    createF2f: function(params) {
        return api.create(API + '/create-f2f', params)
    },
    updateF2f: function(params) {
        return api.create(API + '/update-f2f/'+params.id, params)
    },
}
