<template>
    <el-form ref="form" :model="form"  :rules="rules" style="padding-left: 15px; padding-right: 15px;background: #ebeef5">
      <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Full Name" prop="fullname" class="input-full-width">
                  <el-input style="width:100%" name="fullname" v-model="form.fullname" placeholder="Fullname" size="large" type="text"></el-input>
              </el-form-item>
          </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
              <el-form-item label="Age" prop="age" >
                  <el-input name="age" v-model="form.age" placeholder="Age" size="large" type="number"></el-input>
              </el-form-item>
          </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="Highest Educational Attainment" prop="highest_education_attainment" >
                  <el-input name="highest_education_attainment" v-model="form.highest_education_attainment" placeholder="Highest Educational Attainment" size="large" type="text"></el-input>
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
            <el-form-item label="Citizenship" prop="citizenship">
                    <el-select v-model="form.citizenship" placeholder="Citizenship" size="large" style="width: 100%;">
                      <el-option :label="citizenship.value" :value="citizenship.id" v-for="citizenship in $store.state.citizenships" :key="citizenship.id"></el-option>
                    </el-select>
                  </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Occupation" prop="occupation" >
                  <el-input name="occupation" v-model="form.occupation" placeholder="Occupation" size="large" type="text"></el-input>
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Monthly income" prop="monthly_income" >
                  <el-input  name="monthly_income" v-model="form.monthly_income" placeholder="Monthly income" size="large" type="number"  :step="0.01"></el-input>
              </el-form-item>
          </el-col>
          
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Relation" prop="relation" class="input-full-width">
                  <el-select  name="relation" v-model="form.relation" placeholder="Relation" size="large">
                      <el-option :label="guardian.value" :value="guardian.id" v-for="guardian in $store.state.guardians" :key="guardian.id"></el-option>
                  </el-select>
              </el-form-item>   
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Emergency Contact Person" prop="to_notify" class="input-full-width">
                 <el-switch v-model="form.to_notify"></el-switch>
              </el-form-item>   
          </el-col>
      </el-row>

      <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="Contact no" prop="contact_no" >
                  <el-input  name="contact_no" v-model="form.contact_no" v-mask="'###########'" placeholder="09XXXXXXXXX" size="large" type="text"></el-input>
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="Street No/Brgy" prop="address1" >
                  <el-input  name="address1" v-model="form.address1" placeholder="Street No/Brgy" size="large" type="text"></el-input>
              </el-form-item> 
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="Town" prop="town">
                  <search-town-component :town.sync="form.town" style="width:100%"></search-town-component>
              </el-form-item>
          </el-col>
      </el-row>
  </el-form>
</template>

<script>

import SearchTownComponent from '@/components/common/SearchTownComponent.vue'

export default {
  name: 'GuardianForm',
  props: ['guardian'],
  components:{ SearchTownComponent },
  data() {
    const validateContact = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Contact no is required'));
        return;
      }

      if (value.length < 11) {
        callback(new Error('Invalid Contact number'));
        return;
      }

      if (value.substr(0, 2) !== '09') {
        callback(new Error('Invalid Contact number'));
        return;
      }

      callback();
    };
    return {
      form: this.guardian,
        rules: {
            fullname: [
                { required: true, message: 'Fullname is required', trigger: 'blur' },
            ],
            occupation: [
                { required: true, message: 'Occupation is required', trigger: 'blur' },
            ],
            monthly_income: [
                { required: true, message: 'Monthly income is required', trigger: 'blur'},
            ],
            relation: [
                { required: true, message: 'Please select relation', trigger: 'blur' },
            ],
            contact_no: [
                { validator: validateContact, trigger: 'blur' },
            ],
            address1: [
                { required: true, message: 'Street/Brgy is required', trigger: 'blur' },
            ],
            town: [
                { required: true, message: 'Town or City is required', trigger: 'blur' },
            ],
            age: [
                { required: true, message: 'Age is required', trigger: 'blur' },
            ],
            highest_education_attainment: [
                { required: true, message: 'Required', trigger: 'blur' },
            ],
            citizenship: [
                { required: true, message: 'Citizenship is required', trigger: 'blur' },
            ],
        }
    }
  },
  watch:{
    guardian: {
        deep: true,
        handler() {
          this.validateForm()
        }
    }
  },
  created: function() {
      this.form.valid = false
      if (this.form.town && Object.keys(this.form.town).length === 0) {
          this.form.town = null
      }
      this.$emit('update:guardian', this.form)
      this.validateForm()
  },
  methods: {
    timeOut(time) {
        return new Promise(resolve => {
            setTimeout(resolve, time);
        });
    },
    async validateForm() {
      await this.timeOut(100);
      this.form.valid = false
      this.$emit('update:guardian', this.form)
      const valid = await this.$refs.form.validate();
      this.form.valid = valid
      this.$emit('update:guardian', this.form)
    },
  }
}
</script>