<template>
    <div> 
        <el-row :gutter="20" style="padding:10px">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
               <el-row :gutter="20" style="">
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                    <el-alert
                      title="Scheduled Applicants"
                      type="success"
                      class="bg-orange"
                      effect="dark"
                      :closable="false">
                      <div style="font-size:50px;">{{ result.ready_for_exam}}</div>
                      <i class="el-icon-date dashboard-icon"></i>
                    </el-alert>
                  </el-col>

                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                    <el-alert
                      title="Pending Reviews"
                      type="warning"
                      effect="dark"
                      :closable="false">
                      <div style="font-size:50px;">{{ result.pending}}</div>
                      <i class="el-icon-edit-outline dashboard-icon"></i>
                    </el-alert>
                  </el-col>

                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="margin-bottom: 10px">
                    <el-alert
                      title="Passed"
                      type="success"
                      effect="dark"
                      :closable="false">
                      <div style="font-size:50px;">{{ result.passed}}</div>
                      <i class="el-icon-check dashboard-icon"></i>
                    </el-alert>
                  </el-col>
                
              </el-row>
          </el-col>

           <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Upcoming Schedules</span>
              </div>
              <el-table
                :data="schedules"
                style="width: 100%">
                <el-table-column
                  prop="date"
                  label="Date"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="time_start"
                  label="Start Time"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="time_end"
                  label="End Time">
                </el-table-column>
                <el-table-column
                  prop="entries"
                  label="Entries">
                </el-table-column>
                <el-table-column
                  prop="slots"
                  label="Slots">
                </el-table-column>
              </el-table>

            </el-card>
          </el-col>

        </el-row>

        <el-row :gutter="20" style="padding:20px">
          
        </el-row>
    </div>
</template>

<script>

import { Schedule } from "@/api_services/schedule";
import { Applicant } from "@/api_services/applicant";
import * as moment from 'moment';


export default {
  name: 'Dashboard',
  data() {
    return {
        schedules: [],
        result: {passed: 0, pending: 0, ready_for_exam: 0}
    }
  },
  created: function() {
    this.getSchedules()
    this.getAnalytics()
  },
  methods: {
    getSchedules() {
      let date = moment().format('YYYY-MM-DD')
      Schedule.get({
        params: { page: 1, from: date },
      }).then((result) => {
          this.schedules = result.data.data
      }).catch((error) => {
          console.log(error)
      });
    },
    getAnalytics() {
      Applicant.analytics({}).then((result) => {
          this.result = result.data
      }).catch((error) => {
          console.log(error)
      });
    }
  }
}
</script>

<style scoped>
  .dashboard-icon {
        position: absolute;
    top: 15px;
    right: 15px;
    font-size: 80px;
    opacity: .6;
  }
</style>