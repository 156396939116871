<template>
    <div>
      <el-form ref="form" :model="form" :rules="rules">
          <el-divider content-position="left">Home Address</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Street/House No" prop="address.address1">
                <el-input v-model="form.address.address1" placeholder="street no " size="large" type="text"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <el-form-item label="Barangay" prop="address.address2">
                <el-input v-model="form.address.address2" placeholder="Brgy" size="large" type="text"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="7" :xl="7">
              <el-form-item label="Town" prop="address.town">
                  <search-town-component :town.sync="form.address.town" style="width:100%"></search-town-component>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="5">
              <el-form-item label="Zip code" prop="address.zip_code">
                <el-input v-model="form.address.zip_code" placeholder="Zip code" size="large" type="text"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-divider content-position="left">Parents/Guardian Information</el-divider>
          <guardians-component :applicant="form" @update="setGuardians($event)" :check.sync="check"></guardians-component>
          <el-divider content-position="left">School Attended</el-divider>
          <school-attended-component :applicant="form" @update="setSchoolsAttended($event)" :check.sync="check"></school-attended-component>

      </el-form>
    </div>
</template>

<script>

import SearchTownComponent from '@/components/common/SearchTownComponent.vue'
import SchoolAttendedComponent from '@/components/common/SchoolAttendedComponent.vue'
import GuardiansComponent from '@/components/common/GuardiansComponent'

export default {
  name: 'Step2',
  props: ['form','step', 'check', 'save_counter', 'save_valid'],
  components: {
    SearchTownComponent, SchoolAttendedComponent, GuardiansComponent
  },
  data() {
    return {
      loading: true,
      valid_school: true,
      valid_guardian: true,
      checking: false,
      rules: {
        'address.address1': [
          { required: true, message: 'Street/House No is required', trigger: 'blur' },
        ],
        'address.address2': [
          { required: true, message: 'Barangay is required', trigger: 'blur' },
        ],
        'address.zip_code': [
          { required: true, message: 'Zipcode is required', trigger: 'blur' },
        ],
        'address.town': [
          { required: true, message: 'Town/City is required', trigger: 'blur' },
        ]
      }
    }
  },
  watch: {
    check: function() {
      this.checking = true
      this.checkForm()
    },
    save_counter: function() {
      this.checkForSave()
    }
  },
  created: function() {
    if (!this.form.address) this.form.address = { town: null }
    if (!this.form.address.town) this.form.address.town = null
    this.valid_guardian = true;
    this.valid_school = true;
    this.checking = false
    this.initialCheck()
    console.log(this.step)
  },
  methods: {
    async initialCheck() {
  
       await this.timeOut();
       this.checkForm()
    },
    timeOut() {
        return new Promise(resolve => {
            setTimeout(resolve, 100);
        });
    },
    setSchoolsAttended(event) {
      this.form.schools_attended = event.schools_attended
      this.valid_school = event.valid
    },
    setGuardians(event) {
      this.form.guardians = event.guardians
      this.valid_guardian = event.valid
    },
    async checkForSave() {
      this.$emit('update:save_valid', false)
      const valid = await this.$refs.form.validate();
      if (!valid) return

      if (!this.validateGuardianAndSchools()) {
        return;
      }
      this.$emit('update:save_valid', true)
    },
    async isValidGuardians() {
      let valid = true

      this.form.guardians.forEach(guardian => {
        if ( !guardian.address1 || !guardian.occupation || !guardian.monthly_income || !guardian.relation || !guardian.contact_no || !guardian.fullname || !guardian.town) valid = false
      });
      this.valid_guardian =  valid
    },
    validateGuardians() {
      let valid = true

      this.form.guardians.forEach(function(guardian){
        if (
          !guardian.fullname || !guardian.age || !guardian.highest_education_attainment || !guardian.citizenship
          || !guardian.occupation || !guardian.monthly_income || !guardian.relation || !guardian.contact_no || !guardian.address1
          || !guardian.town
          ) valid = false
      })
     
      return valid
    },
    validateGuardianAndSchools() {
       if (this.form.guardians.length == 0) {
        this.$message({
          message: 'Please add at least one guardian',
          type: 'error',
          duration: 3000
        });
        return false;
      }

      if (!this.valid_guardian) {
        if (this.checking) {
           this.$message({
            message: 'Please complete the guardians section',
            type: 'error',
            duration: 3000
          });
        }
        return false;
      }

      const hasEmergencyContact = this.form.guardians.filter(guardian => {
        return !!guardian.to_notify;
      });

      if (hasEmergencyContact.length === 0) {
        this.$message({
          message: 'Please choose one emergency contact person',
          type: 'error',
          duration: 3000
        });
        return false;
      }

      if (hasEmergencyContact.length > 1) {
        this.$message({
          message: 'Please choose only one emergency contact person',
          type: 'error',
          duration: 3000
        });
        return false;
      }

      if (this.form.schools_attended.length == 0) {
        this.$message({
          message: 'Please add at least one attended school',
          type: 'error',
          duration: 3000
        });
        return false;
      }

    
      if (!this.valid_school) {
        this.$message({
          message: 'Please complete the attended schools section',
          type: 'error',
          duration: 3000
        });
        return false;
      }
      return true
    },
    async checkForm() {
      const valid = await this.$refs.form.validate();
      if (!valid) return
      if (!this.validateGuardianAndSchools()) return
      this.$emit('update:step', 2)
    }
  }
}
</script>
