<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
        <el-card class="box-card" style="text-align: center;" shadow="hover">
              <i class="el-icon-user-solid" style="font-size: 50px;" type="primary">
              </i>
              <div>
                  <el-link type="primary" @click="goTo('users')">Users</el-link>
              </div>
        </el-card>

       

      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
        <el-card class="box-card" style="text-align: center;" shadow="hover">
              <i class="el-icon-s-tools" style="font-size: 50px;" type="primary">
              </i>
              <div>
                  <el-link type="primary" @click="goTo('configure')">Configure</el-link>
              </div>
        </el-card>

       

      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
        <el-card class="box-card" style="text-align: center;" shadow="hover">
              <i class="el-icon-more" style="font-size: 50px;" type="primary">
              </i>
              <div>
                  <el-link type="" >More settings in the future</el-link>
              </div>
        </el-card>
      </el-col>
       
    </el-row>
    
  </div>

</template>


<script>

export default {
  name: 'Settings',
  methods: {
    goTo(name) {
      this.$router.push({name: name})
    }
  }
}
</script>
