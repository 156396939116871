<template>
    <div style="padding: 10px">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2" style="text-align:center;margin-bottom: 10px">
              <el-badge :value="form.revert_ctr" class="item">
              <el-avatar shape="square" :size="100" fit="cover" center>
                <img src="@/assets/img_avatar_male.png" width="100" v-if="form.gender == 'M'"/>
                <img src="@/assets/img_avatar_female.png" width="100" v-if="form.gender == 'F'"/>
              </el-avatar>
              </el-badge>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <h5 style="text-align:left;margin:0px;margin-left:20px">EXAMINEE CONTROL NUMBER (ECN) : {{ form.control_no || 'N/A' }}</h5>
                            
              
              <el-alert
                
                  :title="form.final_status"
                  :type="getAlertStatus(form.final_status)"
                  :closable="false" style="margin-top:10px;margin-bottom:10px">
                </el-alert>
                
            </el-col>
        </el-row>
       
         
        <el-tabs tab-position="top" style="height: 100%;">
          <el-tab-pane label="Applicant Info">
            <el-card>
              <applicant-preview-component :form.sync="form"></applicant-preview-component>
            </el-card>
          </el-tab-pane>
          <el-tab-pane label="Attachments">
            <el-card>
            <applicant-attachments-preview-component :applicant.sync="form"></applicant-attachments-preview-component>
            </el-card>
          </el-tab-pane>
          <el-tab-pane label="Exam Details" v-if="form.status != 5">
            <el-card>
              <applicant-exam-details-component :applicant.sync="form"></applicant-exam-details-component>
              </el-card>
          </el-tab-pane>
          <el-tab-pane label="Revert" v-if="form.status != 5">
            <el-card>
              <applicant-remarks-component :applicant.sync="form"></applicant-remarks-component>
              </el-card>
          </el-tab-pane>
          <el-tab-pane label="Reject">
            <el-card>
              <applicant-reject-component :applicant.sync="form"></applicant-reject-component>
              </el-card>
          </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import ApplicantPreviewComponent from '@/components/applicants/ApplicantPreviewComponent.vue';
import ApplicantExamDetailsComponent from '@/components/applicants/ApplicantExamDetailsComponent.vue';
import ApplicantAttachmentsPreviewComponent from './ApplicantAttachmentsPreviewComponent.vue';
import ApplicantRemarksComponent from './ApplicantRemarksComponent.vue';
import ApplicantRejectComponent from './ApplicantRejectComponent.vue';

export default {
  components: { ApplicantPreviewComponent, ApplicantExamDetailsComponent, ApplicantAttachmentsPreviewComponent, ApplicantRemarksComponent, ApplicantRejectComponent },
  name: 'ApplicantComponent',
  props: ['applicant'],
  data() {
    return {
       male: '@/assets/img_avatar_male.png',
       female: '@/assets/img_avatar_female.png',
       form: null
    }
  },
  created: function() {
    this.form = Object.assign({}, this.applicant);
  },
  methods: {
    getAlertStatus(status) {
      let index = 0
      this.$store.state.final_status.forEach(function(final, i){
        if (final == status) index = i;
      })
      return  this.$store.state.alert_status[index]
    },
  }
}
</script>