<template>
  <div class="Configure" style="padding: 20px;">
    <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          .
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-card>
              <div>
                <div>            
                    <el-row :gutter="20">
                      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" style="padding: 10px">
                            <el-divider content-position="left">Feature will be added soon..</el-divider>
                              
                        </el-col>
                        <!-- <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" style="padding: 10px">
                            <el-divider content-position="left">Date Open / Close Admission Portal</el-divider>
                            <el-button type="warning" size="small" @click="generateResults(Schedule)" :loading="loading" content-position="left">Edit</el-button>
                              <ul style="list-style-type: none;padding-left: 5px;">
                                <li style="padding: 5px;">Open: <el-date-picker type="date" placeholder="Pick a date" value-format="yyyy-MM-dd" style="width: 50%;"></el-date-picker></li>
                                <li style="padding: 5px;">Close: <el-date-picker type="date" placeholder="Pick a date" value-format="yyyy-MM-dd" style="width: 50%;"></el-date-picker></li>
                              
                              </ul>
                        </el-col> -->
                    </el-row>

                    <!-- <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" style="padding: 10px">
                            <el-divider content-position="left">Grade Convertion</el-divider>
                            <el-button type="warning" size="small" @click="generateResults(Schedule)" :loading="loading" content-position="left">Edit</el-button>

                        </el-col>
                    </el-row> -->

                    <!-- <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" style="padding: 10px">
                            <el-divider content-position="left">Admission Qualifiers config </el-divider>
                            <el-button type="warning" size="small" @click="generateResults(Schedule)" :loading="loading" content-position="left">Edit</el-button>
                              <ul style="list-style-type: none;padding-left: 5px;">
                                <li style="padding: 5px;">Passing Rate:</li>
                              
                              </ul>
                        </el-col>
                        

                    </el-row> -->


                </div>
              </div>
            </el-card>
            </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">

        </el-col>
    </el-row>

    <el-dialog title="Add applicant" width="75%" :visible.sync="showDialog">
      <edit-config-component ></edit-config-component>
    </el-dialog>
  </div>

</template>

<style>
  .right {
    text-align: right;
  }

  .add-btn {
    margin-bottom: 1rem;
  }
</style>

<script>

import { Setting } from '@/api_services/setting'
import EditConfigComponent from './EditConfigComponent.vue';

export default {
  components: {
    EditConfigComponent
  },
  name: 'Configure',
  data() {
    return {
      showDialog: false,
      settings: []
    }
  },
  created() {
    this.getSettings();
  },
  methods: {
    addApplicantForm() {
      this.showDialog = true;
    },
    getSettings() {
      Setting.get({
        // params: { page: this.meta.page, term: this.searchForm.name },
      })
        .then((result) => {
          this.settings = result.data.data;
          console.log(this.settings);
          // this.meta = {
          //   page: result.data.meta.current_page,
          //   total: result.data.meta.total,
          //   per_page: result.data.meta.per_page,
          //   pages: result.data.meta.last_page,
          // };
          // this.loading = false;
          // console.log(this.courses);
        })
        .catch((error) => {
          // this.loading = false;
          // this.applicants = [];
        });
      // console.log("test");
    }
  }
}
</script>
