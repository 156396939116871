<template>
    <el-select
    style="width:100%"
    v-model="item"
    remote
    filterable
    placeholder="Please enter a keyword"
    :remote-method="search"
    allow-create
    @change="checkChanged"
    >
    <el-option
      v-for="item in schools"
      :key="item.id"
      :label="item.name"
      :value="item">
    </el-option>
  </el-select>
</template>

<script>

import { School } from '@/api_services/school'

export default {
  name: 'SearchSchool',
  props: ['school', 'check'],
  data() {
    return {
      item: this.school,
      schools: []
    }
  },
  watch: {
    item: function() {
      if (this.item) {
        this.$emit('update:school', this.item)
      }
    }
  },
  created: function() {
      if (!this.item) return
      let found = false
      let item = this.item
      this.schools.forEach(function(school){
          if (item.id == school.id) found = true
      })

      if (!found) this.schools.push(item)
  },
  methods: {
    search(queryString) {
        School.get({params: {term: queryString}})
        .then(result => {
          this.schools = result.data.data
        })
        .catch( error => {
         console.log(error)
         this.schools = []
        })
    },
    checkChanged(event) {
      if (typeof event === 'object' && event !== null) {
        this.item = event
      } else {
        if (typeof this.item === 'object' && this.item !== null) {
          this.item.name = event
        } else {
          this.item = {
            name: event,
            level: null,
            type: null,
            town: null
          }
        }
      }
      this.$emit('change', this.item)
    }
  }
}
</script>