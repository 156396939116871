<template>
     <div>
      <hr>
      <el-link type="primary" href="/" :underline="false">Home page</el-link>
    </div>
</template>

<script>

export default {
  name: 'BackToHomeButton'
}
</script>