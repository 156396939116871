<template>
  <div>
    <el-table
      :data="guardians"
      style="width: 100%;">
      <el-table-column
        prop=""
        label="Parents / Guardian Information"
        style="width: 100%;">
        <template slot-scope="scope">
            <guardian-form-component :guardian.sync="guardians[scope.$index]"></guardian-form-component>
        </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="Action"
            width="80">
            <template slot-scope="scope">
                <div v-if="scope.$index == 1">
                    <el-button style="padding:5px" type="danger" size="small" @click="remove(scope.row, scope.$index)">DELETE</el-button>
                </div>
            </template>
            </el-table-column>
    </el-table>
  
    <div style="text-align:center; margin-top: 5px;"><el-button style="padding:5px" type="warning" plain size="meduim" @click="add()" :disabled="!valid">ADD PARENT/GUARDIAN</el-button></div>
    </div>
</template>

<script>

import GuardianFormComponent from './guardians/GuardianFormComponent.vue'
 
export default {
    name: 'GuardiansComponent',
    props: ['applicant', 'check'],
    components: { GuardianFormComponent },
    data() {
        return {
            valid: false,
            guardians: []
        }
    },
    created: function() {
      this.guardians = this.applicant.guardians
      if (!this.guardians) this.guardians = []
      if (!this.guardians.length) {
          this.guardians.push({
               fullname: null,
               occupation: null,
               monthly_income: null,
               relation: null,
               town: {}
           })
      }
    },
    watch: {
        check: function() {
           this.validateForm()
        },
        guardians: {
            deep: true,
            handler() {
                this.validateForm()
            }
        }
    },
    methods: {
        validateForm() {
            let valid = true
            this.guardians.forEach(function(guardian){
                if (guardian.town) guardian.town_id = guardian.town.id
                if (!guardian.valid) valid = false
            })
            this.valid = valid
            this.$emit('update', { guardians: this.guardians, valid: this.valid})
        },
        remove(item, index) {
            if (this.guardians.length > 1) {
                this.guardians.splice(index, 1)
                this.validateForm()
            } else {
                this.$message({
                    message: 'At least one guardian is required',
                    type: 'error',
                    duration: 3000
                });
            }   
        },
       add() {
           let valid = true
           if (valid) {
            this.guardians.push({
                fullname: null,
                occupation: null,
                monthly_income: null,
                relation: null,
                town_name: null
                })
            this.valid = false
           }
       }
    }
}
</script>