<template>
    <div style="padding: 20px;">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
          <el-divider content-position="left">Moodle Information</el-divider>
                  <el-form ref="form" :model="exam_detail" @submit="onSubmit()"  :rules="rules">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Moodle Username" prop="moodle_username">
                    <el-input v-model="exam_detail.moodle_username" placeholder="Moodle Username" size="large" :readonly="!!exam_detail.id" :disabled="user.role === 'staff'"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Moodle Password" prop="moodle_password">
                    <el-input v-model="exam_detail.moodle_password" placeholder="Moodle Password" size="large" :readonly="!!exam_detail.id" :disabled="user.role === 'staff'">

                      <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover"
                      content="Randomize password"
                      slot="append">
                        <el-button slot="reference" type="success" icon="el-icon-refresh" @click="randomizePassword()" circle></el-button>
                      </el-popover>
                    </el-input>
                   
                  </el-form-item>
                   
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Moodle Link" prop="moodle_link">
                    <el-input v-model="exam_detail.moodle_link" placeholder="Moodle Link" size="large" :readonly="!!exam_detail.id" :disabled="user.role === 'staff'"></el-input>
                  </el-form-item>
                </el-col>
                 <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Schedule" prop="schedule_id">
                    <el-select v-model="exam_detail.schedule_id" placeholder="Schedule" size="large" style="width: 100%;" :disabled="user.role === 'staff'">
                      <el-option label="Select schedule" :value="null"></el-option>
                      <el-option :label="showSchedule(schedule)" :value="schedule.id" v-for="schedule in schedules" :key="schedule.id"></el-option>
                    </el-select>

                  </el-form-item>
                  
                </el-col>
            </el-row>
            <el-form-item style=";margin-top: 20px">
              <el-button type="primary" @click="onSubmit">Approve</el-button>
          </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
          .
        </el-col>

        <div v-if="exam_detail.overall_rating !== null">
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
          <el-divider content-position="left">Result Information</el-divider>
          <el-form ref="resultForm" :model="exam_detail" @submit="onSubmitResult()"  :rules="result_rules">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Result" prop="result">
                      <el-select v-model="exam_detail.result" placeholder="Result" size="large" style="width: 100%;" :disabled="user.role === 'staff'">
                        <el-option :label="result.value" :value="result.id" v-for="result in $store.state.results" :key="result.id"></el-option>
                      </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Cat Score" prop="cat_score">
                    <el-input readonly="readonly" v-model="exam_detail.cat_score" placeholder="Cat Score" size="large" :disabled="user.role === 'staff'"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-form-item label="Over all rating" prop="overall_rating">
                    <el-input readonly="readonly" v-model="exam_detail.overall_rating" placeholder="Over all rating" size="large" :disabled="user.role === 'staff'"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
               <el-form-item style=";margin-top: 20px">
                  <el-button type="primary" @click="onSubmitResult">Save</el-button>
              </el-form-item>
          </el-form>
        </el-col>
        </div>  

      </el-row>
          
    </div>
</template>

<script>

import { Applicant } from '@/api_services/applicant'
import { Schedule } from '@/api_services/schedule'
import * as moment from 'moment';


export default {
  name: 'ApplicantExamDetail',
  props: ['applicant'],
   data() {
    return {
      schedules: [],
      user: this.$store.state.savedUser,
      exam_detail: {
        moodle_link: null,
        moodle_username: null,
        moodle_password: null,
        cat_score: null,
        result: null,
        overall_rating: null,
        schedule_id: 1,
        applicant_id: this.applicant.id
      },
      rules: {
        moodle_link: [
          { required: true, message: "Moodle link is required", trigger: "blur"}
        ],
        moodle_username: [
          { required: true, message: "Moodle username is required", trigger: "blur"}
        ],
        moodle_password: [
          { required: true, message: "Moodle password is required", trigger: "blur"}
        ],
        schedule_id: [
          { required: true, message: "Schedule is required", trigger: "blur"}
        ]
      },
      result_rules: {
        cat_score: [
          { required: true, message: "CAT Score is required", trigger: "blur"}
        ],
        result: [
          { required: true, message: "Result is required", trigger: "blur"}
        ],
        overall_rating: [
          { required: true, message: "Over all rating is required", trigger: "blur"}
        ]
      }
    }
  },
  created: function() {
    if (this.applicant.applicant_exam_detail) {
      this.exam_detail = this.applicant.applicant_exam_detail
    }

    if (!this.exam_detail.moodle_password) {
      this.randomizePassword();
    }

    if (!this.exam_detail.moodle_username) {
      this.generateUsername();
    }
    
    if (!this.exam_detail.moodle_link) {
      this.exam_detail.moodle_link = 'https://cat-exam1.vsu.edu.ph/';
    }
    this.getSchedules()
  },
  methods: {
    randomizePassword() {
      const alpha = "abcdefghijklmnopqrstuvwxyz";
      const numbers = "0123456789";
      const symbols = "!@#$_+";

      const characters = alpha + numbers;

      let password = '';
      for (let i = 0; i < 5; i++) {
        password += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }

      password = alpha.charAt(Math.random() * alpha.length).toUpperCase() + password;
      password += numbers.charAt(Math.floor(Math.random() * numbers.length));
      password += symbols.charAt(Math.floor(Math.random() * symbols.length));

      this.exam_detail.moodle_password = password;
    },
    generateUsername() {
      const first = this.applicant.firstname.charAt(0).toLowerCase();
      // const middle = this.applicant.middlename.charAt(0).toLowerCase();
      let middle = "";

      if (this.applicant.middlename) {
        middle = this.applicant.middlename.charAt(0).toLowerCase();
      }

      this.exam_detail.moodle_username = first + middle + this.applicant.lastname.replace(/\s+/g, '').replace(/ñ/g,"n").toLowerCase();
    },
    showSchedule(schedule) {
      let day = moment(schedule.date).format('ddd');
      let start = schedule.time_start.split(":")[0]+":"+schedule.time_start.split(":")[1];
      let end = schedule.time_end.split(":")[0]+':'+schedule.time_end.split(":")[1];
      let available = Math.max(schedule.slots - schedule.entries, 0)
      return moment(schedule.date).format('MMMM D, YYYY')+" ("+day+") | "+start+' - '+end+" ("+available+" open)"
    },
    getSchedules() {
      Schedule.get({params: {available: true, from: new Date().toISOString().split('T')[0], per_page: 100}}).then(result => {
          this.schedules = result.data.data
          this.addApplicantSchedule()
        })
        .catch( error => {
          console.log(error)
        })
    },
    addApplicantSchedule() {
      let found = false;
      let self = this;
      if (!this.exam_detail.schedule) return
      this.schedules.forEach(function(sched){
          if (sched.id === self.exam_detail.schedule.id) found = true
      })

      if (!found) this.schedules.push(this.exam_detail.schedule)
    },
    async onSubmit() {
        const valid = await this.$refs.form.validate();
        if (!valid) return;

        if (this.exam_detail.id) {
          Applicant.updateSchedule(this.exam_detail.applicant_id, this.exam_detail)
          .then(result => {
            if (result) {
              this.$message({
                message: "Schedule updated succesfully",
                type: 'success',
                duration: 3000
              });
            this.getSchedules()
              // this.applicant.status = result.data.data.status
              // this.applicant.control_no = result.data.data.control_no
              // this.applicant.final_status = result.data.data.final_status
              // // this.applicant.control_no = result.data.data.control_no
            }           
          })
          .catch( error => {
            this.loading = false
            console.log(error)
          })
          return;
        }

        Applicant.completeApplication(this.exam_detail.applicant_id, this.exam_detail)
        .then(result => {
          if (result) {
            this.$message({
              message: "Exam details has been saved successfully",
              type: 'success',
              duration: 3000
            });
          this.getSchedules()
            this.applicant.status = result.data.data.status
            this.applicant.control_no = result.data.data.control_no
            this.applicant.final_status = result.data.data.final_status
            // this.applicant.control_no = result.data.data.control_no
          }           
        })
        .catch( error => {
          this.loading = false
          console.log(error)
        })
    },
    async onSubmitResult() {
        const valid = await this.$refs.resultForm.validate();
        if (!valid) return;

        Applicant.updateExamResult(this.exam_detail.applicant_id, this.exam_detail)
        .then(result => {
          if (result) {
            this.$message({
              message: "Exam result has been saved successfully",
              type: 'success',
              duration: 3000
            });
            this.applicant.status = result.data.data.status
            this.applicant.control_no = result.data.data.control_no
            this.applicant.final_status = result.data.data.final_status
          }           
        })
        .catch( error => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>