
<template>
    <div>            
      <el-form
      :model="form"
        ref="form"
        label-width="20px"
      >
      
          <el-tabs tab-position="top" style="height: 100%;">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="21">
                  <span style="color:#ffffff">. </span>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
                  <el-button style="margin-bottom: 10px;" size="small" type="warning" @click="editApplicant"> EDIT</el-button>
                </el-col>
            </el-row>
          <el-tab-pane label="Applicant Info">
            <el-card>
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4" style="text-align:center;margin-bottom: 10px">
                  <el-badge :value="form.revert_ctr" class="item">
                  <el-avatar shape="square" :size="100" fit="cover" center>
                    <img src="@/assets/img_avatar_male.png" width="100" v-if="form.gender == 'M'"/>
                    <img src="@/assets/img_avatar_female.png" width="100" v-if="form.gender == 'F'"/>
                  </el-avatar>  
                  </el-badge>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <h5 style="text-align:left;margin:0px;margin-left:20px">EXAMINEE CONTROL NUMBER (ECN) : {{ form.control_no || 'N/A' }}</h5>
                                
                  
                  <el-alert
                    
                      :title="form.final_status"
                      :type="getAlertStatus(form.final_status)"
                      :closable="false" style="margin-top:10px;margin-bottom:10px">
                    </el-alert>
                    
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <el-divider content-position="left">Basic Information</el-divider>
                  <ul style="font-size: 13px; list-style-type: none;padding-left: 5px;">
                    <li style="padding: 5px;">First Name : <b style=" text-transform: uppercase;">{{ form.firstname }}</b></li>
                    <li style="padding: 5px;">Middle Name : <b style=" text-transform: uppercase;">{{ form.middlename }}</b></li>
                    <li style="padding: 5px;">Last Name : <b style=" text-transform: uppercase;">{{ form.lastname }}</b></li>
                    <li style="padding: 5px;">Ext : <b style=" text-transform: uppercase;">{{ form.extension }}</b></li>
                    <li style="padding: 5px;">Email : <b>{{ form.email }}</b></li>
                    <li style="padding: 5px;">Sex : <b style=" text-transform: uppercase;">{{ form.gender }}</b></li>
                    <li style="padding: 5px;">Contact No : <b style=" text-transform: uppercase;">{{ form.contact_no }}</b></li>
                    <li style="padding: 5px;">Birth place : <b style=" text-transform: uppercase;">{{ form.birthplace }}</b></li>
                    <li style="padding: 5px;">Birth date : <b style=" text-transform: uppercase;">{{ form.birth_date }}</b></li>
                    <li style="padding: 5px;">Citizenship : <b style=" text-transform: uppercase;">{{ form.citizenship }}</b></li>
                    <li style="padding: 5px;">Civil status : <b style=" text-transform: uppercase;">{{ form.civil_status }}</b></li>
                    <li style="padding: 5px;">Name of Spouse : <b style=" text-transform: uppercase;">{{ form.spouse_name }}</b></li>
                    <li style="padding: 5px;">Number of children : <b style=" text-transform: uppercase;">{{ form.no_of_children }}</b></li>
                    <li style="padding: 5px;">Religion : <b style=" text-transform: uppercase;">{{ form.religion ? form.religion.name : "" }}</b></li>
                    
                  </ul>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-divider content-position="left">Home Address</el-divider>

                  <ul style="font-size: 13px; list-style-type: none;padding-left: 5px;">
                    <li style="padding: 5px;">Street No / Bldg : <b style=" text-transform: uppercase;">{{ form.address ? form.address.address1 : ""}}</b></li>
                    <li style="padding: 5px;">Brgy : <b style=" text-transform: uppercase;">{{ form.address ? form.address.address2 : ""}}</b></li>
                    <li style="padding: 5px;">Town/City :  <b style=" text-transform: uppercase;">{{ form.address ? form.address.town.name : ""}}</b></li>
                    <li style="padding: 5px;">Province :  <b style=" text-transform: uppercase;">{{ form.address ? form.address.province.name : ""}}</b></li>
                  </ul>
                  
              </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-divider content-position="left">Other Information</el-divider>
            <ul style="font-size: 13px; list-style-type: none;padding-left: 5px;">
              
              <li style="padding: 5px;">Degree program : <b style=" text-transform: uppercase;">{{ form.course ? form.course.description : "" }} {{ form.course && form.course.major ? " major in " + form.course.major: "" }}</b></li>
              <li style="padding: 5px;">Preferred Campus : <b style=" text-transform: uppercase;">{{ form.campus }}</b></li>
              <li style="padding: 5px;">Grade 9 GPA : <b style=" text-transform: uppercase;">{{ form.grade_9_gpa }} </b></li>
              <li style="padding: 5px;">Grade 10 GPA : <b style=" text-transform: uppercase;">{{ form.grade_10_gpa }} </b></li>
              <li style="padding: 5px;">Grade 11 GPA : <b style=" text-transform: uppercase;">{{ form.grade_11_gpa }} </b></li>
              <li style="padding: 5px;">Latest Education Attainment : <b style=" text-transform: uppercase;">{{ getHsTypeName(form.hs_type) }} </b></li>
              <li style="padding: 5px;">GPA: <b>{{ form.hs_type_gpa }}</b></li>
              <li style="padding: 5px;margin-left: 15px;color:#6f6f6f"></li>
              <!-- <div v-if="form.strand !== null">
                <li style="padding: 5px;">SHS Strand : </li>
                <li style="padding: 5px;margin-left: 15px;color:#6f6f6f">
                </li>
              </div>

              <div v-if="form.gender === 'F'">
                <li style="padding: 5px;">First female in the family to attend college: </li>
                <li style="padding: 5px;margin-left: 15px;color:#6f6f6f"></li>
              </div>

              <div v-if="form.gender === 'M'">
                <li style="padding: 5px;">First male in the family to attend college: </li>
                <li style="padding: 5px;margin-left: 15px;color:#6f6f6f"></li>
              </div> -->
              
              <li style="padding: 5px;">Social Categories : </li>
              <li style="padding: 5px;margin-left: 15px;" v-for="social in getSocialCategoriesList()" :key="social"><b>{{ social}}</b></li>
              <li style="padding: 5px;">Any allergies or health problem : <b style=" text-transform: uppercase;">{{ form.health_problems || 'None' }}</b></li>
              <!-- <li style="padding: 5px;margin-left: 15px;"><b>{{ form.pwd_handicap || 'None' }}</b></li> -->
            </ul>
        </el-col>

      </el-row>
      
      <el-divider content-position="left">Guardians</el-divider>
        <ul style="list-style-type: none;padding-left: 5px;">
            <li style="padding: 5px;">
            <el-table
              size="mini"
              >
                <el-table-column
                  prop="fullname"
                  label="Fullname"
                  >
                </el-table-column>
                <el-table-column
                prop="occupation"
                label="Occupation"
                >
                </el-table-column>
                <el-table-column
                prop="monthly_income"
                label="Monthly income">
                </el-table-column>
                <el-table-column
                prop="relation"
                label="Relation"
                >
                </el-table-column>
                <el-table-column
                prop="contact_no"
                label="Contact No"
                >
                </el-table-column>
                <el-table-column
                prop="address1"
                label="Street/Brgy"
                >
                </el-table-column>
              <el-table-column
                label="Town">
              </el-table-column>
              
            </el-table>
          </li>
        </ul>

      <el-divider content-position="left">Attended Schools</el-divider>
      <!-- <ul style="list-style-type: none;padding-left: 5px;margin-bottom: 20px;">
          <li style="padding: 5px;">
          <el-table
          size="mini"
            style="width: 100%">
              <el-table-column
                prop="date_attended"
                label="Year Graduated"
                >
                <template slot-scope="scope">
                {{  scope.row.date_attended }}
              </template>

              </el-table-column>
              <el-table-column
              label="School"
          >
              <template slot-scope="scope">
                {{ scope.row.school ? scope.row.school.name : ''}}
              </template>
            </el-table-column>
            <el-table-column
              label="Type"
              width="100">
              <template slot-scope="scope">
                {{ scope.row.school ? scope.row.school.type : ''}}
              </template>
            </el-table-column>
            <el-table-column
              label="Level"
              width="100">
              <template slot-scope="scope">
                {{ scope.row.school ? getSchoolLevel(scope.row.school.level) : ''}}
              </template>
            </el-table-column>
            <el-table-column
              label="Type"
              >
              <template slot-scope="scope">
                {{ scope.row.school && scope.row.school.town ? scope.row.school.town.name+', '+scope.row.school.town.province.name : ''}}
              </template>
            </el-table-column>
          
          </el-table>
        </li>
      </ul> -->

      

          <!-- <el-dialog title="Update Email" width="40%" :visible.sync="showDialog" :destroy-on-close.sync="destroyOnClose">
            <update-email-component :selected="selected" @saved="emailSaved"></update-email-component>
          </el-dialog>

          <el-dialog title="Update Degree Program" width="40%" :visible.sync="showDialogcourse" :destroy-on-close.sync="destroyOnClose">
            <update-course-component :selected="selected" @saved="emailSaved"></update-course-component>
          </el-dialog>

          <el-dialog title="Update GPA" width="20%" v:on:close-dialog="closeDialog" :visible.sync="showDialogGPA" :destroy-on-close.sync="destroyOnClose">
            <update-gpa-component :selected="selected" @saved="emailSaved"></update-gpa-component>
          </el-dialog> -->
            </el-card>
          </el-tab-pane>
          <el-tab-pane label="Exam Details">
            <el-card>
              <!-- <f2f-applicant-exam-details-component :applicant.sync="form"></f2f-applicant-exam-details-component> -->
              <el-row :gutter="20">

                <div>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-divider style="font-size: 13px;" content-position="left">Result Information</el-divider>
                  <ul style="font-size: 13px; list-style-type: none;padding-left: 5px;">
                    <li style="padding: 5px;">Exam Score: </li>   
                  </ul>
                </el-col>
                </div>  

              </el-row>
              </el-card>
          </el-tab-pane>
          
        </el-tabs>
        
    
        </el-form>
        <el-dialog title="Edit applicant" width="95%" :visible.sync="showDialog" :before-close="handleClose" :destroy-on-close="true">
          <add-applicant-component @button-done="done" :selected="selected"></add-applicant-component>
        </el-dialog>
    </div>
</template>

<script>

import * as moment from 'moment';
import AddApplicantComponent from './AddApplicantComponent.vue';

export default {
  name: 'F2fApplicantDetailsComponent', 
  components: {
    AddApplicantComponent
  },
  props: ["selected"],
  data() {
    return {
      destroyOnClose: true,
      showDialog: false,
      user: this.$store.state.savedUser,
      applicant: null,
      form: [],
      selected_id: null,
      componentKey: 0,
    }
  },
  created() {
    
    this.form = this.selected;
    console.log("form");
    console.log(this.form);
  },
  methods: {
    getAlertStatus(status) {
      let index = 0
      this.$store.state.final_status.forEach(function(final, i){
        if (final == status) index = i;
      })
      return  this.$store.state.alert_status[index]
    },
    getHsTypeName(hs_type) {
        let string = ""
        this.$store.state.hs_types.forEach(function(type){
          if (type.id == hs_type ) {
            string = type.value
          }
        })  

      return string
    },
    getSocialCategoriesList() {
      let list = [];
      let self = this
      if (!this.form.social_categories) { return []; }
      this.form.social_categories.forEach(function(social,index){
        if (social) list.push(self.$store.state.social_category_names[index])
      })

      return list;
    },
    editApplicant(){
      this.showDialog = true;
    },
    reloadComponent() {
      this.componentKey += 1;
    },
    handleClose(){
      this.$confirm('Are you sure to cancel editing? unsaved inputs will be reverted')
        .then(_ => {
          this.showDialog = false;
          done();
        })
        .catch(_ => {});
    },
    done(){
      this.showDialog = false;
    },
  }
}
</script>