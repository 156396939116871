<template>
  <el-row style="margin:20px;">
  <el-col :span="4">.</el-col>
  <el-col :span="16" :xs="24" style="margin-bottom: 5rem; position: relative">

     <el-row>
        <el-col :xs="24" :md="8" :lg="8" style="color: #ffffff;">.</el-col>
       <el-col :xs="24" :md="8" :lg="8">
         <div style="text-align:center">
            <img :src="require('@/assets/images/vsu_logo_green.png')"  style="margin-bottom:20px;width:100%">
          </div>
       </el-col>
     </el-row>

     <div v-loading.fullscreen.lock="loading_status == 'loading'" style="padding: 10px;min-height: 50px;"
          element-loading-text="Checking availablity..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)">
       
       <div v-if="loading_status == 'closed'">
          <h3 style="text-align:center">{{ message }}</h3>
        </div>

     </div>
    
    <div v-if="status == 1" style="margin-bottom: 30px">
      <el-alert title="" center type="error" :closable="false">
        <h1>INVALID LINK</h1>
      </el-alert>
    </div>

    <div v-if="status >= 2">
      <div v-if="submitted"  style="margin-bottom: 80px;margin-top:80px;">
        <el-alert show-icon
              style="margin-bottom: 20px;"
              title=""
              :closable="false"
              type="success"
              effect="dark">
              <h3>You have {{ already_submitted ? 'already': 'successfully' }} submitted your application form. Please check your email from time to time for further instructions or announcements</h3>
          </el-alert>

          <p style="color:#818884">You can check you application status <el-link type="primary" href="guest" :underline="false">here</el-link></p>
          <!-- <el-alert
            :type="getAlertType(form.final_status)"
            :closable="false"
            effect="dark">
            <div>STATUS: {{ form.final_status }}</div>
          </el-alert> -->

          
      </div>
      <div v-if="!submitted">
        <el-alert show-icon
          style="margin-bottom: 20px;"
          title=""
          :closable="false"
          type="success"
          effect="dark">
          <h3>Email Verified</h3>
        </el-alert>
        <el-alert v-if="form.revert_ctr && close == 0" show-icon
          style="margin-bottom: 20px;"
          title=""
          :closable="false"
          type="warning"
          effect="dark">
          <h3>Reverted</h3>
          <h4>Please review the remarks that were included in the email you received, and update your application accordingly.</h4>
        </el-alert>
        <el-alert v-if="close == 1" 
          show-icon
          style="margin-bottom: 20px;"
          title=""
          :closable="false"
          type="warning"
          effect="dark">
          <h3>We're sorry, but the application submission period has already ended.</h3>
          <h3>Please feel free to contact us for further information at <u>cat2023@vsu.edu.ph</u> or <u>admission@vsu.edu.ph</u></h3>
        </el-alert>
        <el-card v-if="close == 0" class="box-card">
          <h3 class="application-form">VSU Admission Application form</h3>
          <el-steps :space="300" :active="step" finish-status="success">
            <el-step title="Step 1" icon="el-icon-edit">1</el-step>
            <el-step title="Step 2" icon="el-icon-s-home">2</el-step>
            <el-step title="Step 3" icon="el-icon-s-tools">3</el-step>
            <el-step title="Step 4" icon="el-icon-upload">4</el-step>
            <el-step title="Step 5" icon="el-icon-s-claim">5</el-step>
          </el-steps>

          <step-1 :save_counter.sync="save_counter" :save_valid.sync="save_valid"  :check.sync="check" :step.sync="step" :form.sync="form" v-if="step == 0"></step-1>
          <step-2 :save_counter.sync="save_counter" :save_valid.sync="save_valid"  :check.sync="check" :step.sync="step" :form.sync="form" v-if="step == 1"></step-2>
          <step-3 :refresh.sync="refresh" :save_counter.sync="save_counter" :save_valid.sync="save_valid" :check.sync="check" :step.sync="step" :form.sync="form" v-if="step == 2"></step-3>
          <step-3b :refresh.sync="refresh" :save_counter.sync="save_counter" :save_valid.sync="save_valid" :check.sync="check" :step.sync="step" :applicant.sync="form" v-if="step == 3"></step-3b>
          <step-4 :save_counter.sync="save_counter" :save_valid.sync="save_valid" :check.sync="check" :step.sync="step" :form.sync="form" v-if="step >= 4"></step-4>
         
          <div style="text-align:center;margin-top: 20px;">
            <el-button type="primary" @click="back()" v-if=" step > 0"> Back </el-button>
            <el-button type="success" @click="onSave()" icon="el-icon-message" :loading="loading" v-if="step < 3"> Save</el-button>
            <el-button type="primary" @click="next()"> {{ step > 3 ? 'Submit':'Next' }}</el-button> 
            <p style="font-size: 11px;color: #908e8e;">Note: You can save your application form information and continue any time</p>
          </div>
        </el-card>
        <el-dialog
          title="Attention"
          :visible.sync="submitDialog"
          width="40%"
          style="background: #fbcc00;"
          :close-on-click-modal="false"
          class="responsive-dialog"
          >
          <span>You are about to submit your application form. Proceed ?</span>
          <span slot="footer" class="dialog-footer">
            <el-button  @click="submitDialog = false">Back</el-button>
            <el-button type="primary" @click="confirmSubmit" :loading="loading">Yes</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <back-to-home />
  </el-col>
  <el-col :span="4" style="color: #ffffff;">.</el-col>
</el-row>
</template>

<script>

import { Applicant } from '@/api_services/applicant'
import { Setting } from '@/api_services/setting'

import Step1 from './ApplicationsPage/Step1.vue'
import Step2 from './ApplicationsPage/Step2.vue'
import Step3 from './ApplicationsPage/Step3.vue'
import Step3b from './ApplicationsPage/Step3B.vue'
import Step4 from './ApplicationsPage/Step4.vue'

export default {
  name: 'Start',
   components: {
    Step1, Step2, Step3, Step3b, Step4
  },
  data() {
    return {
      loading_status: 'open',
      message: '',
      submitDialog: false,
      loading: false,
      form: {},
      step: 0,
      applicant: null,
      submitted: false,
      status: 0,
      already_submitted: false,
      check: 0,
      save_counter: 0,
      save_valid: false,
      save_only: false,
      is_back: false,
      refresh: 0,
      close_unsubmitted: 0,
      close_reverted: 0,
      close: 0,
      closeDialog: false
    }
  },
  watch: {
    refresh: function() {
      this.refreshData()
      // console.log("step =" + this.step)
    },
    step: function() {
      if (this.step >= 5) {
        this.submitDialog = true
        // console.log("submitting " + this.step)
      } else {
        // console.log("this x=" + this.step)
        if (!this.is_back) {
          this.save_only = false 
          this.confirmSubmit()
        }
      }
    },
    save_valid:function() {
      this.save_only = false
      if (this.save_valid) {
        this.save_only = true 
        this.confirmSubmit()
      }
    }
  },
  created: function() {
    this.validateLink()
    this.closeSettings()
  },
  methods: {
    isApplicationOpen() {
      Setting.getOpenAndClose({})
        .then(result => {  
          this.loading_status = (result.data.is_open) ? 'open' : 'closed'
          if (this.loading_status == 'open') {
            this.validateLink()
          } else {
            this.message = result.data.message
          }
        })
        .catch( error => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
              duration: 3000
            });
            this.loading_status = 'loading'
          }
        }).then(() => {
        });
    },
    getAlertType(final_status) {
      let success = [ 'ACCEPTED','QUALIFIED']
      let warning = ['READY FOR REVIEW']
      let error = ['REJECTED']
      if (success.includes(final_status)) return 'success'
      if (warning.includes(final_status)) return 'warning'
      if (error.includes(final_status)) return 'error'

    },
    confirmAction() {
       this.$confirm('You are about to submit your application form. Continue?', 'Attention', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'info',
          center: true
        }).then(() => {
            this.confirmSubmit()
        }).catch(() => {
          this.step = 4
        });
    },
    validateLink() {
      this.status = 0
     
      Applicant.verifyToken(this.$route.query)
        .then(result => {
          if (result) {
            this.status = 2
            this.transformData(result.data.data);
          } 
        })
        .catch( error => {
          console.error(error)
          this.status = 1
        })
    },
    refreshData() {
      Applicant.verifyToken(this.$route.query)
        .then(result => {
          if (result) {
            this.status = 2
            this.transformData(result.data.data);
          } 
        })
        .catch( error => {
          console.error(error)
        })
    },
    transformData(data) {
      this.form = data
      this.form.step = this.step
      this.form.token = this.$route.query.token
      this.form.school_id = 1
      if (data.status >= 2) {
        this.already_submitted = true
        this.submitted = true;
      }
      if (!this.form.address) this.form.address = {}
      if (this.form.religion) this.form.religion_id = this.form.religion.id
      if (this.form.guardians && this.form.guardians.length > 0) {
        this.form.guardians.forEach((guardian) => {
          if (guardian.town) guardian.town_id = guardian.town.id;
        });
      }

      if (this.form.schools_attended && this.form.schools_attended.length > 0) {
        this.form.schools_attended.forEach((school_attended) => {
          if (school_attended.school && school_attended.school.town) school_attended.school.town_id = school_attended.school.town.id;
        });
      }

      if (this.form.strand) { this.form.strand_id = this.form.strand.id }
      else {
        this.form.strand = null
        // this.form.other_stand =
      }
    },
    back() {
      this.is_back = true
      if (this.step > 5) this.step = 5
      this.step--
      if (this.step < 0) this.step = 0
    },
    next() {
      this.is_back = false
      this.check++
      if (this.step >= 4) this.submitDialog = true
    },
    onSave() {
      this.is_back = false
      this.save_counter++
    },
    filteredStep1Data(applicant) {
      let form = {
        step: applicant.step,
        firstname: applicant.firstname,
        middlename: applicant.middlename,
        lastname: applicant.lastname,
        extension: applicant.extension,
        id: applicant.id,
        email: applicant.email,
        token: applicant.token,
        birth_date: applicant.birth_date,
        birthplace: applicant.birthplace,
        citizenship: applicant.citizenship,
        civil_status: applicant.civil_status,
        contact_no: applicant.contact_no,
        religion_id: applicant.religion_id,
        gender: applicant.gender,
        strand_id: applicant.strand_id,
        revert_ctr: applicant.revert_ctr,
      }
      return form
    },
    filteredStep2Data(applicant) {
      let form = {
        step: applicant.step,
        guardians: applicant.guardians,
        address: applicant.address,
        schools_attended: applicant.schools_attended,
        id: applicant.id,
        email: applicant.email,
        token: applicant.token
      }
      return form
    },
    closeSettings(){
      Setting.getCloseSettings()
      .then((result) => {
        this.close_unsubmitted = result.data.close_unsubmitted;
        this.close_reverted = result.data.close_reverted;

        if (this.close_unsubmitted > 0 && !this.form.revert_ctr) {
          this.close = 1;
        } else if (this.close_reverted > 0 && this.form.revert_ctr) {
          this.close = 1;
        } else {
          this.close = 0;
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    confirmSubmit() {
      this.loading = true
      if (this.form.course) this.form.course_id = this.form.course.id
      if (this.form.address.town) this.form.address.town_id = this.form.address.town.id

      this.form.schools_attended.forEach(function(school_attended){
        if (school_attended.level) {
          school_attended.school.level = school_attended.level
        }
        if (school_attended.type) {
          school_attended.school.type = school_attended.type
        }
        if (school_attended.town) {
          school_attended.school.town_id = school_attended.town.id
        }
      });

      this.form.step = this.step
      let form = Object.assign({}, this.form);
      if (!this.save_only) {
        form.step -= 1
        if (form.step == 0) {
          form = this.filteredStep1Data(form)
        }
        if (form.step == 1) {
          form = this.filteredStep2Data(form)
        }
      }

      if(form.civil_status !== 2){
        form.spouse_name = null;
        form.no_of_children = null;
      }

      Applicant.updateDetails(form)
        .then(result => {
          if (result) {
            this.loading = false
            if (!this.save_only) {
              if (form.step >= 4) {
                this.dialogVisible = false
                this.submitted = true
              }
              window.scrollTo(0, 50)
            } else {
              this.$message({
                message: "Information has been saved.",
                type: 'success',
                duration: 1500
              });
              this.save_only = false
              this.transformData(result.data.data)
            }
            
          } 
        })
        .catch( error => {
          this.loading = false
          this.save_only = false
          if (error.status === 400) {
            if (error.data.message != 'Degree program does not exist on selected campus') {
              this.back();
            } 
          } else {
            this.status = 1
          }
        })
    }
  }
}
</script>
