<template>
  <div class="Email">
    <el-form
    :model="emailForm"
      ref="emailForm"
      label-width="100px"
      @submit.native.prevent="submitForm()" 
      :rules="rules"
    >
      <el-form-item label="New Email" prop="email">
        <el-input type="text" v-model="emailForm.email" style="width: 80%;"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm">Save</el-button>
        <!-- <el-button @click="resetForm">Reset</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Applicant } from '@/api_services/applicant'
export default {
  name: "UpdateEmailComponent",
  props: ["selected"],

  data() {
    return {
      emailForm: [],
      rules: {
        email: [
          { required: true, message: 'Email address is required', trigger: 'blur' },
          { type: 'email', message: 'Invalid email address', trigger: ['blur', 'change'] }
        ],
      }
    };
  },
  created() {
    
    this.emailForm = this.selected;
    console.log(this.emailForm);
  },
  methods: {
    // resetForm() {
    //   this.$refs.courseForm.resetFields();
    // },
    async submitForm() {
      this.update();
      console.log("test");
    },
    update() {
      Applicant.updateEmail({email: this.emailForm.email, id:this.emailForm.id})
        .then((response) => {
          // console.log(this.email);
          this.$emit("saved", response.data.data);
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
    }
  },
};
</script>
