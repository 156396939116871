<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item  v-for="(item,i) in list" :key="i">
            <el-link :disabled="isLast(i)" type="primary" @click="goTo(item)"> {{ formatString(item) }}</el-link>
        </el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>

export default {
  name: 'Breadcrumb',
  data() {
    return {
        list: [],
    }
  },
  watch:{
        $route (to, from){
             this.getList()
        }
    },
  created: function() {
      this.getList()
  },
  methods: {
    formatString(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
      isLast(i) {
        return  i+1 >= this.list.length
      },
      getList() {
        this.list = this.$router.currentRoute.path.split('/');
        this.list = this.list.filter(n => n)
      },
      goTo(name) {
          if (name !== this.$router.currentRoute.name) this.$router.push({name: name})
      }
  }
}
</script>