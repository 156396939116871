<template>
  <el-select
  :disabled="disabled"
    style="width:100%"
    v-model="item"
    remote
    filterable
    placeholder="Please enter a keyword"
    :remote-method="search"
    >
    <el-option
      v-for="item in towns"
      :key="item.id"
      :label="getName(item)"
      :value="item">
    </el-option>
  </el-select>
</template>

<script>

import { Town } from '@/api_services/town'

export default {
  name: 'SearchTown',
  props: ['town', 'check','disabled'],
  data() {
    return {
      item: this.town,
      towns: []
    }
  },
  watch: {
    check: function() {
      this.item = this.town
      this.addItemToTowns()
    },
    town: function() {
      this.item = this.town
      this.addItemToTowns()
    },
    item: function() {
      if (this.item) {
        this.$emit('update:town', this.item)
        this.$emit('change', this.item)
      }
    }
  },
  created: function() {
      this.addItemToTowns()
  },
  methods: {
    addItemToTowns() {
      if (!this.item) return
      let found = false
      let item = this.item
      this.towns.forEach(function(town){
          if (item.id == town.id) found = true
      })

      if (!found) this.towns.push(item)
    },
    getName(item) {
      if (item && item.province) return item.name + ', ' + item.province.name
      return  ""
    },
    search(queryString) {
        Town.get({params: {term: queryString}})
        .then(result => {
          let links = this.gatherItems(result.data.data)
          this.towns = links
        })
        .catch( error => {
         console.log(error)
         this.towns = []
        })
    },
    gatherItems(items) {
        items.forEach(item => {
            item.value = item.name+', '+item.province.name
        });
        return items;
    },
  }
}
</script>