<template>
    <div style="padding: 20px;">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4" v-for="attachment in getAttachments()" :key="attachment.id" style="margin-bottom:10px">
              <el-card :body-style="{ padding: '10px' }">
                
                <div style="max-height: 200px;overflow: hidden;">
                  <img src="@/assets/image.png" class="image" style="width:100%" v-if="isPdf(attachment.url)">
                  <img :src="attachment.full_path" class="image" style="width:100%" v-if="!isPdf(attachment.url)">
                </div>
                <div style="padding: 14px;">
                    <div style="font-size: 12px;">{{ getFormType(attachment.type)}}</div>
                  <div style="font-size: 12px;min-height: 12px;">{{ getFormTypeNote(attachment.type)}}</div>
                  
                    <div class="bottom clearfix">
                     <el-link type="primary" :href="attachment.full_path" target="_blank">Download</el-link>
                    </div>
                    <!-- <div class="bottom clearfix">
                     <el-link type="primary" :href="attachment.full_path" target="_blank">Preview</el-link>
                    </div> -->
                </div>
                
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>


export default {
  name: 'ApplicantAttachmentsPreviewComponent',
  props: ['applicant'],
   data() {
    return {
      
    }
  },
  created: function() {
    
  },
  methods: {
    getAttachments() {
      const attachmentTypes = [];
      const attachmentMap = this.$store.state.attachment_types_map.find(attachmentType => attachmentType.hs_type === this.applicant.hs_type);

      attachmentMap.form_types.forEach(type => {
        attachmentTypes.push(type);
      });

      this.applicant.social_categories.forEach((cat, idx) => {
        if (cat === true) {
          const socialMapIndex = this.$store.state.social_categories_and_attachment_types[idx];
          attachmentTypes.push(socialMapIndex);
        }
      });

      return this.applicant.attachments.filter(attachment => {
        return attachmentTypes.includes(attachment.type)
      });
    },
    isPdf(url) {
      return url.includes('pdf')
    },
   getFormType(type) {
       let res = ""
      this.$store.state.form_types.forEach(function(f){
        if (f.id == type) res = f.value
      })
      return res
   },
   getFormTypeNote(type) {
       let res = ""
      this.$store.state.form_types.forEach(function(f){
        if (f.id == type && f.note) res = f.note
      })
      return res
   }
  }
}
</script>