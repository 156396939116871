<template>
  <div>
    <el-form ref="form" 
    :model="form" 
    @submit.native.prevent="onSubmit()">
          <el-divider content-position="left">Basic Info</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="First Name">
                <el-input></el-input></el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="5">
              <el-form-item label="Middle Name">
                <el-input></el-input></el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <el-form-item label="Last Name">
                <el-input></el-input></el-form-item>
            </el-col>
             <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
              <el-form-item label="Extension">
                <el-input></el-input>
              </el-form-item>
            </el-col>
          </el-row>

           <el-row :gutter="20">
           

            <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
               <el-form-item label="Email">
                <el-input></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <el-form-item label="Contact No">
                  <el-input></el-input>
                </el-form-item>
              </el-col>
          </el-row>
         
          
           <div style="text-align:center;margin-top: 20px;">
                <el-button type="success" > Submit</el-button>
            </div>
      </el-form>
  </div>
</template>

<script>
// import { Course } from "@/api_services/course";
export default {
  name: "EditConfigComponent",

  data() {
    return {
      
    };
  },
  created() {
    
  },
  methods: {
    
  },
};
</script>
