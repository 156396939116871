<template>
  <div class="course">
    <el-form
      :model="courseForm"
      :rules="rules"
      ref="courseForm"
      label-width="200px"
      class="demo-ruleForm"
    >
      <el-form-item label="Degree Program Code" prop="code">
        <el-input v-model="courseForm.code"></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input v-model="courseForm.description"></el-input>
      </el-form-item>
      <el-form-item label="Major" prop="major">
        <el-input v-model="courseForm.major"></el-input>
      </el-form-item>
      <el-form-item label="Campuses" prop="campuses">
        <el-select multiple v-model="courseForm.campuses" placeholder="Campus" size="large" style="width: 100%;">
          <el-option :label="campus.value" :value="campus.id" v-for="campus in campuses" :key="campus.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Status" prop="status">
        <el-switch v-model="courseForm.status"></el-switch>
      </el-form-item>
      <el-form-item label="Quota (MAIN campus only)">
        <el-input v-model="courseForm.quota" style="width: 10%"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm">Save</el-button>
        <el-button @click="resetForm">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Course } from "@/api_services/course";
export default {
  name: "CourseComponent",
  props: ["selected"],

  data() {
    return {
      courseForm: {
        code: "",
        description: "",
        campuses: "",
        major: "",
        status: "",
        quota: ""
      },
      campuses: this.$store.state.campuses,
      timeStartOpt: {},
      rules: {
        code: [
          { required: true, message: "Please input code", trigger: "blur" },
        ],
        description: [
          {
            required: true,
            message: "Please input description",
            trigger: "blur",
          },
        ],
        campuses: [
          {
            required: true,
            message: "Please select campuses",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    console.log('hree', this.selected);
    if (this.selected) {

      this.courseForm = this.selected;
      this.courseForm.campuses = this.selected.campuses.split(',');
    }
  },
  methods: {
    resetForm() {
      this.$refs.courseForm.resetFields();
    },
    async submitForm() {
      const valid = await this.$refs.courseForm.validate();
      if (!valid) return;

      this.courseForm.campuses = this.courseForm.campuses.join(',').toLowerCase();

      if (this.selected && this.selected.id) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      Course.create(this.courseForm)
        .then((response) => {
          this.$emit("saved", response.data.data);
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
    },
    update() {
      Course.update(this.selected.id, this.courseForm)
        .then((response) => {
          this.$emit("saved", response.data.data);
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
    }
  },
};
</script>
