<template>
  <div style="text-align:center;margin:20px;" >
    <el-row >
      <el-col :span="9">.</el-col>
      <el-col :xs="24" :md="6" :lg="6">
        <div>
          <img :src="require('@/assets/images/vsu_logo_green.png')" style=" width: 100%;margin-bottom: 50px">
        </div>
        <div v-if="status == 'closed'">
          <h3 >{{ message }}</h3>
          <!-- <h4 >{{ message_title }}</h4>
          <h5 >{{ message_body }}</h5> -->
        </div>
        <div  v-loading.fullscreen.lock="status == 'loading'" style="padding: 10px;min-height: 50px;"
          element-loading-text="Checking availablity..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div v-if="status == 'open'">
            <div v-if="submitted">
              <h3>We have sent you the link for your application form</h3>
            </div>
            <div v-if="!submitted">
              <h3>Let's verify your email first</h3>
              <el-form :model="form" @submit.native.prevent="submitForm('ruleForm')" :rules="rules" ref="ruleForm">
                <el-form-item label="" prop="email">
                  <el-input v-model="form.email" placeholder="Ex. juan.delacruz@gmail.com" size="large"></el-input>
                </el-form-item>
                
                <el-form-item>
                  <el-button native-type="submit" type="primary" >Submit</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <p  style="text-align: left; font-size: 10px;color: #F56C6C;">
              <i>
              <li> Active Gmail account is highly recommended</li>
              <li> Email should be professional in format (eg.: firstname.lastname@gmail.com or firstname_lastname@gmail.com)</li>
              <li> Unprofessional email format is discouraged (eg.: bacardigirl@gmail.com or ferrari123@gmail.com)</li>
              </i>
          </p>
        </div>
        <back-to-home />
      </el-col>
      <el-col :span="9">.</el-col>
    </el-row>
    <!-- <el-dialog
      class="application-dialog"
      title=""
      :visible.sync="centerDialogVisible"
      width="40%"
      center>
      <terms-and-condition-component></terms-and-condition-component>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">I do not agree</el-button>
        <el-button type="primary" :disabled="!!submitting" @click="onSubmit">I Agree</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Schema from 'async-validator';
import { Applicant } from '@/api_services/applicant'
import { Setting } from '@/api_services/setting'


// import TermsAndConditionComponent from '@/components/common/TermsAndConditionComponent.vue';


export default {
  // components: { TermsAndConditionComponent },
  name: 'Start',
  data() {
    return {
      form: {
        email: ''
      },
      ruleForm: {
        email: ''
      },
      status: 'loading',
      message: '',
      submitted: false,
      submitting: false,
      centerDialogVisible: false,
      rules: {
          email: [
            { required: true, message: 'Email address is required', trigger: 'blur' },
            { type: 'email', message: 'Invalid email address', trigger: ['blur', 'change'] }
          ],
      },
      message_body: '',
      message_title: ''
    }
  },
  created: function() {
    this.isApplicationOpen()
    this.closeSettings()
  },
  methods: {
    isApplicationOpen() {
      Setting.getOpenAndClose({})
        .then(result => {  
          this.status = (result.data.is_open) ? 'open' : 'closed'
          this.message = result.data.message
        })
        .catch( error => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
              duration: 3000
            });
            this.status = 'loading'
          }
        }).then(() => {
        });
    },
    closeSettings(){
      Setting.getCloseSettings()
      .then((result) => {
        // console.log(result.data);
        this.message_body = result.data.message_body;
        this.message_title = result.data.message_title;
      }).catch((error) => {
        console.log(error);
      });
    },
    async submitForm() {
      const valid = await this.$refs.ruleForm.validate();
      if (!valid) return;
      this.onSubmit();
      if (!this.submitted) {this.submitting = false;}
      // this.centerDialogVisible = true
    },
    onSubmit() {
      if (this.submitting) { return; }
      this.submitting = true;
      Applicant.storeEmail(this.form)
        .then(result => {
          this.submitted = true
          // this.centerDialogVisible = false
        })
        .catch( error => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
              duration: 3000
            });
          }
        }).then(() => {
          this.submitting = false;
        });
    }
  }
}
</script>
