<template>
  <div>
    <el-table
      :data="schools_attended"
      style="width: 100%">
      <el-table-column
        prop="Fullname | Occupation | Monthly Income | Relation"
        label="Schools attended list"
        style="width: 100%">
        <template slot-scope="scope">
            <school-attended-form-component :school_attended.sync="schools_attended[scope.$index]" ></school-attended-form-component>
        </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="Action"
            width="80">
            <template slot-scope="scope">
                <div v-if="scope.$index == 1">
                    <el-button style="padding:5px" type="danger" size="small" @click="remove(scope.row, scope.$index)">DELETE</el-button>
                </div>
            </template>
            </el-table-column>
    </el-table>
    <div style="text-align:center; margin-top: 5px;"><el-button style="padding:5px" type="warning" plain size="medium" @click="add()" :disabled="!valid">ADD SCHOOL ATTENDED</el-button></div>
    </div>
</template>

<script>

import { School } from '@/api_services/school'
import SchoolAttendedFormComponent from './schools_attended/SchoolAttendedFormComponent.vue'

export default {
    name: 'GuardiansComponent',
    props: ['applicant', 'check'],
    components: { SchoolAttendedFormComponent },
    data() {
        return {
            schools: [],
            show_school: false,
            valid: false,
            schools_attended: [],
            form: {},
            forms:[],
           
            empty_school_attended: {
                level: null,
                type:null,
                date_attended: null,
                school: {
                    name: null,
                    town: null,
                    type: null,
                    level: null
                }
           }
        }
    },
    created: function() {
      this.schools_attended = this.applicant.schools_attended
      if (!this.schools_attended) this.schools_attended = []
      if (!this.schools_attended.length) {
          this.schools_attended.push(this.empty_school_attended)
      }
    },
    watch: {
        check: function() {
            this.validateForm()
        },
        schools_attended: {
            deep: true,
            handler() {
               this.validateForm()
            }
        }
    },
    methods: {
        validateForm() {
            let valid = true
            this.schools_attended.forEach(function(school_attended){
            if (school_attended.school) {
                school_attended.school_id = school_attended.school.id
                if (!school_attended.school.name) valid = false
                if (school_attended.school.town && school_attended.school.town.id) school_attended.town_id = school_attended.school.town.id
            }
            if (school_attended.town && school_attended.town.id) school_attended.town_id = school_attended.town.id
            if (!school_attended.level || !school_attended.type
                || !school_attended.date_attended
            ) valid = false
                if (school_attended.school && !school_attended.level)  school_attended.level = school_attended.school.level
                if (school_attended.school && !school_attended.type)  school_attended.type = school_attended.school.type
                if (school_attended.school && !school_attended.town)  school_attended.town = school_attended.school.town
            })
            
            this.valid = valid
            this.$emit('update', { schools_attended: this.schools_attended, valid: this.valid})
            
        },
        remove(item, index) {
            if (this.schools_attended.length > 1) {
                this.schools_attended.splice(index, 1)
                this.validateForm()
            } else {
                this.$message({
                    message: 'At least one attende school is required',
                    type: 'error',
                    duration: 3000
                });
            }   
        },
       add() {
           this.schools_attended.push({
               date_attended: null,
               school: {
                   name: null,
                   town: null,
                   type: null,
                   level: null
               },
               level: null,
               type: null
           })

            this.valid = false
       },
    }
}
</script>