<template>
  <div class="Email">
    <el-form
    :model="form"
      ref="form"
      label-width="100px"
      class="demo-ruleForm"
    >

      <!-- <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4"> -->
        <el-form-item label="Campus" prop="campus">
          <el-select v-model="form.campus" placeholder="Campus" size="large" style="width: 100%;">
            <el-option :label="campus.value" :value="campus.id" v-for="campus in $store.state.campuses" :key="campus.id"></el-option>
          </el-select>
        </el-form-item>
        
      <!-- </el-col> -->
      <!-- <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4"> -->
        <el-form-item label="Course" prop="course">
          <search-course-component :course.sync="form.course" style="width:100%" :campus="form.campus" ></search-course-component>
        </el-form-item>

        <el-form-item>
            <el-button type="primary" @click="submitForm">Save</el-button>
            <!-- <el-button @click="resetForm">Reset</el-button> -->
        </el-form-item>
      
      <!-- </el-col> -->
    </el-form>
  </div>
</template>

<script>
import SearchCourseComponent from '@/components/common/SearchCourseComponent.vue'
import { Course } from "@/api_services/course";
export default {
    components: { SearchCourseComponent },
  name: "UpdateEmailComponent",
  props: ["selected"],

  data() {
    return {
      form: [],
    };
  },
  created() {
    this.form = this.selected;
    console.log(this.form);
  },
  methods: {
    // resetForm() {
    //   this.$refs.courseForm.resetFields();
    // },
    async submitForm() {
      
    },
    update() {
      Course.update(this.selected.id, this.courseForm)
        .then((response) => {
          this.$emit("saved", response.data.data);
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
    }
  },
};
</script>
