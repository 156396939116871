<template>
    <div>
      <el-tag style="margin-bottom:10px" class="el-tag-green" type="">Please provide the reason(s) for rejecting the applicant</el-tag>  
      <el-form ref="form"  :inline="false" class="demo-form-inline" :model="form" :rules="rules" @submit.native.prevent>
        <el-form-item label="Remarks" prop="reject_remarks">
          <el-input type="textarea" :rows="3" v-model="form.reject_remarks" placeholder="Remarks" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="small">{{ applicant.status == 5 ? 'Re-send reject email' : 'Reject'}}</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>

import { Applicant } from '@/api_services/applicant'

export default {
  name: 'ApplicantReject',
  props: ['applicant'],
  data() {
    return {
       form: { reject_remarks: null},
        rules: {
          reject_remarks: [
            { required: true, message: 'Remarks is required', trigger: 'blur' },
          ],
        }
    }
  },
  created: function() {
    this.form.reject_remarks = this.applicant.reject_remarks
  },
  methods: {
    async onSubmit() {
       const valid = await this.$refs.form.validate();
      if (!valid) return;

        Applicant.rejectApplication(this.applicant.id, this.form)
        .then(result => {
          if (result) {
            this.$message({
              message: "Applicant has been rejected. An email notification has been sent as well",
              type: 'success',
              duration: 5000
            });
            this.$emit('update:applicant', result.data.data)
          }           
        })
        .catch( error => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>