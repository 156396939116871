<template>
  <div class="schedule">
    <el-form
      :model="scheduleForm"
      :rules="rules"
      ref="scheduleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="Date" prop="date">
        <el-date-picker type="date" placeholder="Pick a date" 
          v-model="scheduleForm.date"
          value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
      </el-form-item>
      <el-form-item label="Time Start" prop="time_start">
         <el-time-select
          placeholder="Start time"
          v-model="scheduleForm.time_start"
          :picker-options="{
            start: '08:00',
            step: '00:15',
            end: '18:30'
          }">
        </el-time-select>
      </el-form-item>
      <el-form-item label="Time End" prop="time_end">
        <el-time-select
          placeholder="End time"
          v-model="scheduleForm.time_end"
          :picker-options="{
            start: '08:00',
            step: '00:15',
            end: '18:30',
            minTime: scheduleForm.time_start
          }">
        </el-time-select>
      </el-form-item>
      <el-form-item label="Slots" prop="slots">
        <el-input v-model="scheduleForm.slots"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('scheduleForm')">Save</el-button>
        <el-button @click="resetForm('scheduleForm')">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Schedule } from '@/api_services/schedule';
export default {
  name: "ScheduleComponent",
  props: ["selected"],

  data() {
    return {
      scheduleForm: {
          date: '',
          time_start: '',
          time_end: '',
          slots: 250
      },
      timeStartOpt: {
       
      },
      rules: {
        date: [
          { required: true, message: 'Please input Date', trigger: 'blur' },
        ],
        time_start: [
          { required: true, message: 'Please input Time Start', trigger: 'blur' },
        ],
        time_end: [
          { required: true, message: 'Please input Time End', trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    console.log(this.selected);
    this.scheduleForm = Object.assign({}, this.selected);
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async submitForm(formName) {
      const valid = await this.$refs.scheduleForm.validate();
      if (!valid) return;

      if (this.selected && this.selected.id) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      Schedule.create(this.scheduleForm).then(response => {
        this.$emit('saved', response.data.data);
      }).catch((error) => {
        if (error.response.data.message) {
          this.$message({
            message: error.response.data.message,
            type: 'error',
            duration: 3000
          });
        }
      });
    },
    update() {
      Schedule.update(this.selected.id, this.scheduleForm).then(response => {
        this.$emit('saved', response.data.data);
      }).catch((error) => {
        if (error.response.data.message) {
          this.$message({
            message: error.response.data.message,
            type: 'error',
            duration: 3000
          });
        }
      });
    }
  }
};
</script>
