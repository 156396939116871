<template>
    <div>
      <el-tag style="margin-bottom:10px" class="el-tag-green" type="">Please select on the revert remark(s) then click save to send email notification to applicant</el-tag>  
      <el-form ref="form"  :inline="false" class="demo-form-inline" :model="form" :rules="rules" @submit.native.prevent>

        <el-checkbox style="margin: 5px" v-model="form.selected_remarks" :label="revert_remark" v-for="revert_remark in $store.state.revert_remarks" :key="revert_remark">{{revert_remark}} 
        </el-checkbox> 

        <p size="small" style="font-size: 11px; color:red;">Previous Remarks: {{ prev_remarks }}</p>
        <el-form-item label="Additional Remarks" prop="remarks">
          <el-input type="textarea" :rows="3" v-model="form.remarks" placeholder="Additional Remarks" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="small" :disabled="!!submitting"	>Save and Revert</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>

import * as moment from 'moment';
import { Applicant } from '@/api_services/applicant'

export default {
  name: 'ApplicantRemarks',
  props: ['applicant'],
  data() {
    return {
      submitting: false,
      prev_remarks: [],
       form: { remarks: null, selected_remarks: [] },
        rules: {
          remarks: [
            { required: true, message: 'Remarks is required', trigger: 'blur' },
          ],
        }
    }
  },
  created: function() {
    this.form.remarks = this.applicant.remarks
    this.prev_remarks = this.applicant.selected_remarks
  },
  methods: {
    async onSubmit() {
      // let i = ['Hello','World!','Beautiful','Day!'];
      // let x = this.selected_remarks;
      // console.log(x);

      if (this.submitting) { return; }
      this.submitting = true;
      
       const valid = await this.$refs.form.validate();
      if (!valid) return;
        this.$router.push({name: "applicants"});
        Applicant.revertApplication(this.applicant.id, this.form)
        .then(result => {
          if (result) {
            this.$message({
              message: "Applicant has been reverted. An email notification has been sent as well",
              type: 'success',
              duration: 5000
            });
            this.$emit('update:applicant', result.data.data)
          }           
        })
        .catch( error => {
          this.loading = false
          console.log(error)
          this.submitting = false
        })
    },
  }
}
</script>