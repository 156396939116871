export const LocalStorageService = {
  getToken: function() {
    return localStorage.getItem('token');
  },
  setToken: function(token: any) {
    localStorage.setItem('token', token);
  },
  getUser: function() {
    let user = localStorage.getItem('user')
    if (user) user = JSON.parse(user);
    return user;
  },
  setUser: function(user: any) {
    localStorage.setItem('user', user ? JSON.stringify(user) : '');
  },
  getGuest: function() {
    let guest = localStorage.getItem('guest')
    if (guest) guest = JSON.parse(guest);
    return guest;
  },
  setGuest: function(guest: any) {
    localStorage.setItem('guest', guest ? JSON.stringify(guest) : '');
  }
}