import api from './api'
import store from '../store/index'

const API = '/api/auth/'

export const Session = {
    login(params, cb) {
        api.create(API+'login', params).then(result => {
        let token = result.data.access_token
        store.commit('saveToken', token)
        
        this.getUser(function(response){
            if (response.id) {
                store.commit('saveUser',response);
                }
                cb(response, "")
            })
        })
        .catch(error => {
            console.log(error)
            let message = 'An error occured while signing in'
            if (error.data) {
                if (error.data.message) message = error.data.message
            }
            cb(false, message)
        })
    },
    me() {
        return api.create(API+'me')
    },
    getUser: function(cb) {
        this.me().then(result => {
          cb(result.data)
        })
        .catch(error => {
          console.log(error)
          cb(error)
        })
      }
}
