<template>
  <div>
    <el-form ref="form" 
    :model="form" 
    :rules="rules"
    @submit.native.prevent="onSubmit()"
    >

    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-divider content-position="left">A. PERSONAL INFORMATION</el-divider>
          <el-row :gutter="5">
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>Last Name</i></span>
              <el-form-item prop="lastname">
                <el-input v-model="form.lastname" placeholder="Last Name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>First Name</i></span>
              <el-form-item prop="firstname">
                <el-input v-model="form.firstname" placeholder="First Name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>Middle Name</i></span>
              <el-form-item prop="middlename">
                <el-input v-model="form.middlename" placeholder="Middle Name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2">
              <span style="font-size: 13px;"><i>Ext.</i></span>
              <el-form-item prop="extension">
                <el-input v-model="form.extension" placeholder="Jr., III"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
              <span style="font-size: 13px;"><i>Contact no.</i></span>
              <el-form-item prop="contact_no">
                <el-input v-model="form.contact_no" placeholder="Contact no."></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          
          <el-row :gutter="5">
            <p content-position="left">Home Address</p>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>Street/Barangay</i></span>
              <el-form-item prop="address.address1">
                <el-input  v-model="form.address.address1" placeholder="Street/Barangay"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>Barangay</i></span>
              <el-form-item prop="address.address2">
                <el-input  v-model="form.address.address2" placeholder="Province"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <span style="font-size: 13px;"><i>Municipal/City, Province</i></span>
              <el-form-item prop="address.town">
                <search-town-component :town.sync="form.address.town" style="width:100%;"></search-town-component>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
              <span style="font-size: 13px;"><i>Zip code</i></span>
              <el-form-item prop="address.zip_code">
                <el-input  v-model="form.address.zip_code" placeholder="Zip code"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="5">
            <el-divider content-position="left"> </el-divider>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <span style="font-size: 13px;"><i>Sex</i></span>
              <el-form-item prop="gender">
                <el-select v-model="form.gender" placeholder="Sex" size="large" style="width: 100%;">
                  <el-option :label="gender.value" :value="gender.id" v-for="gender in $store.state.genders" :key="gender.id"></el-option>
                </el-select>    
                <el-checkbox v-model="form.is_sex_pnts" ><i style="font-size: 11px">Please check if you prefer not to say it.</i></el-checkbox>  
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
              <span style="font-size: 13px;"><i>Age</i></span>
              <el-form-item prop="Age">
                <el-input placeholder="Age"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>Date of Birth</i></span>
              <el-form-item prop="birth_date">
                <el-date-picker type="date" value-format="yyyy-MM-dd" style="width: 100%" v-model="form.birth_date" placeholder="Date of Birth"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>Place of Birth</i></span>
              <el-form-item prop="birthplace">
                <el-input v-model="form.birthplace" placeholder="Place of Birth"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="5">
            <p content-position="left"> </p>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>Civil Status</i></span>
              <el-form-item prop="birthplace">
                <el-select v-model="form.civil_status" placeholder="Civil Status" size="large" style="width: 100%;">
                  <el-option :label="status.value" :value="status.id" v-for="status in $store.state.statuses" :key="status.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <span style="font-size: 13px;"><i>Name of Spouse (if married):</i></span>
              <el-form-item prop="spouse_name">
                <el-input v-model="form.spouse_name" placeholder="Name of Spouse (if married):"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
              <span style="font-size: 13px;"><i>No. of children</i></span>
              <el-form-item prop="no_of_children">
                <el-input type="number" v-model="form.no_of_children" placeholder="No. of children"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row :gutter="5">
            <p content-position="left"> </p>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <span style="font-size: 13px;"><i>Citizenship</i></span>
              <el-form-item prop="citizenship">
                <el-select v-model="form.citizenship" placeholder="Citizenship" size="large" style="width: 100%;">
                  <el-option :label="citizenship.value" :value="citizenship.id" v-for="citizenship in $store.state.citizenships" :key="citizenship.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <span style="font-size: 13px;"><i>Email Address</i></span>
              <el-form-item prop="email">
                <el-input v-model="form.email" placeholder="Email Address"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <span style="font-size: 13px;"><i>Religion</i></span>
              <el-form-item prop="religion_id">
                <el-select filterable v-model="form.religion" value-key="id" placeholder="Religion" class="religion-select" style="width: 100%">
                  <el-option :label="religion.name" :value="religion" v-for="religion in religions" :key="religion.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-bottom: 10px; margin-top: 10px;">
            <p style="font-size: 13px;"><i>Social Categories</i></p>
            <el-checkbox  style="margin: 5px; font-style: italic; font-size: 12px;" @change="handleSocialCategoriesChange($event, index)" v-model="social_categories[index]" :label="social" v-for="(social,index) in $store.state.social_category_names" :key="social">{{social}} 
            </el-checkbox>
          </el-row>

          <el-row v-if="form.social_categories && form.social_categories[4]">
            <span style="font-size: 13px;"><i>*If PWD, what is your physical disability/handicap?</i></span>
            <el-form-item prop="pwd_handicap">
              <el-input v-model="form.pwd_handicap"  placeholder="Physical disability/handicap" type="text"></el-input>
            </el-form-item>
          </el-row>

          <el-row>
            <span style="font-size: 13px;"><i>Any allergies or health problem?</i></span>
            <el-form-item prop="health_problems">
              <el-input v-model="form.health_problems" placeholder="Any allergies or health problem?" type="text"></el-input>
            </el-form-item>
          </el-row>

          <el-row>
            <el-divider content-position="left">B. FAMILY INFORMATION</el-divider>
            <f2f-guardians-component :applicant="form" @update="setGuardians($event)" :check.sync="check"></f2f-guardians-component>
          </el-row>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        
        <el-row>
          <el-divider content-position="left">C. SCHOOLS ATTENDED</el-divider>
          <f2f-school-attended-component :applicant="form" @update="setSchoolsAttended($event)" :check.sync="check"></f2f-school-attended-component>
        </el-row>
        
        <!-- CAMPUS, DEGREE PROGRAM AND COURSE HERE -->
        <el-divider content-position="left">OTHER INFORMATION</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <span style="font-size: 13px;"><i>Campus</i></span>
            <el-form-item prop="campus">
              <el-select v-model="form.campus" placeholder="Campus" size="large" style="width: 100%;">
                <el-option :label="campus.value" :value="campus.id" v-for="campus in $store.state.campuses" :key="campus.id"></el-option>
              </el-select>
            </el-form-item>
            
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
            <span style="font-size: 13px;"><i>Course</i></span>
            <el-form-item prop="course">
              <search-course-component :course.sync="form.course" style="width:150%" :campus="form.campus" ></search-course-component>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
            <span style="font-size: 13px;"><i>Latest Education Attainment</i></span>
            <el-form-item prop="hs_type">
              <el-select v-model="form.hs_type" size="large" style="width: 100%;" @change="handleCategoryChange">
                <el-option :label="hs_type.value" :value="hs_type.id" v-for="hs_type in $store.state.hs_types" :key="hs_type.id"></el-option>
              </el-select>
          </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
            <span style="font-size: 13px;"><i>Obtained GPA or Rating</i></span>
            <el-form-item prop="hs_type_gpa">
              <el-input placeholder="0.00" v-model="form.hs_type_gpa" type="number" :readonly="form.hs_type == 1"></el-input>
          </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-if="form.hs_type === 1">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <span style="font-size: 13px;"><i>Grade 9 GPA</i></span>
            <el-form-item prop="grade_9_gpa">
              <el-input placeholder="0.00" v-model="form.grade_9_gpa" type="number" @change="handleCategoryChange"></el-input>
          </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <span style="font-size: 13px;"><i>Grade 10 GPA</i></span>
            <el-form-item prop="grade_10_gpa">
              <el-input placeholder="0.00" v-model="form.grade_10_gpa" type="number" @change="handleCategoryChange"></el-input>
          </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <span style="font-size: 13px;"><i>Grade 11 GPA</i></span>
            <el-form-item prop="grade_11_gpa">
              <el-input placeholder="0.00" v-model="form.grade_11_gpa" type="number" @change="handleCategoryChange"></el-input>
          </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="SHS Strand" prop="strand"><!-- TODO: select input -->
                <el-select :key="select_strand_key" :disabled="is_other_strand" filterable v-model="form.strand" value-key="id"  placeholder="SHS Strand" size="large" class="strand-select">
                  <el-option :label="strand.strand_code + ' - ' + strand.strand_description" :value="strand" v-for="strand in strands" :key="strand.id"></el-option>
                </el-select>
                <el-checkbox v-model="is_other_strand" @change="handleChangeStrand()" style="margin-left: 10px;">Others</el-checkbox>
              </el-form-item>
          </el-col>
          <el-col v-if="is_other_strand" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
                <el-form-item prop="other_strand_code">
                  <el-input v-model="form.other_strand_code" placeholder="Strand code" type="text" style="width: 100%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <el-form-item prop="other_strand_desc">
                  <el-input v-model="form.other_strand_desc" placeholder="Strand Description" type="text" style="width: 100%"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <div v-if="form.gender === 'F'">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <span style="font-size: 13px;"><i>Are you the first female in your family to attend college? This refers to all the female students who are the first in their immediate family (parents and siblings)</i></span>
            <el-form-item style="margin-bottom:0px" prop="is_first_female">
              <el-select v-model="form.is_first_female" value-key="id">
                <el-option label="No" value="No"></el-option>
                <el-option label="Yes" value="Yes"></el-option>
              </el-select>
            </el-form-item>
            
          </el-col>
        </el-row>
        </div>
        <div v-if="form.gender === 'M'">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <span style="font-size: 13px;"><i>Are you the first male in your family to attend college? This refers to all the male students who are the first in their immediate family (parents and siblings)</i></span>
            <el-form-item style="margin-bottom:0px" prop="is_first_male">
              <el-select v-model="form.is_first_male" value-key="id">
                <el-option label="No" value="No"></el-option>
                <el-option label="Yes" value="Yes"></el-option>
              </el-select>
            </el-form-item>
            
          </el-col>
        </el-row>
        </div>
        
        <el-divider content-position="left">EXAM RESULT INFORMATION</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
            <span style="font-size: 13px;"><i>Exam Score</i></span>
            <el-form-item prop="cat_score">
              <el-input placeholder="0.00" v-model="form.cat_score" type="number"></el-input>
          </el-form-item>
          </el-col>
        </el-row>

      </el-col>

    </el-row>
          
        
    <div style="text-align:center;margin-top: 20px;">
      <el-button  @click="onSubmit()" type="success" > Submit</el-button>
    </div>
    </el-form>
  </div>
</template>

<script>

import { Applicant } from "@/api_services/applicant";
import { Religion } from '@/api_services/religion'
import { Strand } from '@/api_services/strand'

import F2fGuardiansComponent from './F2fGuardianComponent.vue'
import F2fSchoolAttendedComponent from './F2fSchoolAttendedComponent.vue'
import SearchTownComponent from '@/components/common/SearchTownComponent.vue'
import SearchCourseComponent from '@/components/common/SearchCourseComponent.vue'

export default {
  name: "AddApplicantComponent",
  components:{
    F2fGuardiansComponent, SearchTownComponent, F2fSchoolAttendedComponent, SearchCourseComponent
  },
  props: ["selected"],
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Email is required'));
        return;
      }
      callback();
    };
    return {
      form: {},
      social_categories: [],
      rules: {
        firstname: [
          { required: true, message: 'First name is required', trigger: 'blur' },
        ],
        lastname: [
          { required: true, message: 'Last name is required', trigger: 'blur' },
        ],
        gender: [
          { required: true, message: 'Sex is required', trigger: 'blur' },
        ],
        email: [
          { type: 'email', message: 'Invalid email address', trigger: ['blur', 'change'] },
          { trigger: 'blur' , validator: validateEmail}
        ],
        // 'address.town': [
        //   { required: true, message: 'Town/City is required', trigger: 'blur' },
        // ],
        pwd_handicap: [
          { required: false, message: 'PWD handicap is required', trigger: 'blur' },
        ]
      },
      check: null,
      religions: [],
      religion_id: null,
      valid_guardian: true,
      valid_school: true,
      strands: [],
      strand_id: null,
      is_first_female: null,
      is_first_male: null,
      select_strand_key: 'default_key',
      is_other_strand: false,
      origin_strand: null,
    };
  },
  watch: {
    'form.religion': function() {
      this.form.religion_id = this.form.religion.id;
    }
  },
  created() {
    this.getReligions()
    this.getStrands()
    if (this.selected) this.form = this.selected
    if (!this.form.address) this.form.address = { town: null }
    if (!this.form.address.town) this.form.address.town = null
    // if (!this.form.hs_type) this.form.hs_type = null
    console.log(this.$store.state);
    
  },
  methods: {
    setReligions(religions) {
      this.religions = religions;

      this.religions.forEach(religion => {
        if (religion.name.length > 30) {
          religion.name = religion.name.slice(0, -(religion.name.length - 30)) + '...';
        }
      });

      if (this.form.religion) {
        this.form.religion_id = this.form.religion.id;
      }
    },
    getReligions() {
      if (this.$store.state.religions.length) {
        this.setReligions(this.$store.state.religions);
      } else {
        Religion.get({
          params: { page: 1, per_page: 999 },
        }).then(result => {
          if (result) {
            this.$store.state.religions = result.data.data;
            this.setReligions(result.data.data);
          } 
        }).catch( error => {
          console.error(error)
        })
      }
    },
    handleSocialCategoriesChange(value, index) {
      if (!this.form.social_categories) {
        this.form.social_categories = [false, false, false, false, false]
      }
      this.form.social_categories[index] = value
      this.rules.pwd_handicap.required = false
      if (index == 4) {
        if (value) {
          this.rules.pwd_handicap[0].required = true
        }
      }
    },
    onSubmit() {
      this.checkForm()
    },
    async checkForm() {
      const valid = await this.$refs.form.validate();
      if (!valid) return
      this.submitApplicant()
    },
    submitApplicant(){
      if (this.form.course) this.form.course_id = this.form.course.id
      if (this.form.address.town) this.form.address.town_id = this.form.address.town.id
      console.log(this.form);

      if(!this.selected){
        Applicant.createF2f(this.form)
        .then((result) => {
          console.log(result)
          this.$message({
            message: 'Saved successfully.',
            type: 'success',
            duration: 5000
          }); 
          this.$emit('button-done');
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        Applicant.updateF2f(this.form)
        .then((result) => {
          console.log(result)
          this.$message({
            message: 'Updated successfully.',
            type: 'success',
            duration: 5000
          }); 
          this.$emit('button-done');
        })
        .catch((error) => {
          console.log(error);
        });

      }
      


    },
    setGuardians(event) {
      this.form.guardians = event.guardians
      this.valid_guardian = event.valid
    },
    setSchoolsAttended(event) {
      this.form.schools_attended = event.schools_attended
      this.valid_school = event.valid
    },
    handleCategoryChange() {
      if (this.form.hs_type == 1) {
        const sum = (this.form.grade_9_gpa * 1) + (this.form.grade_10_gpa * 1) + (this.form.grade_11_gpa * 1);

        this.form.hs_type_gpa = (sum / 3).toFixed(3);
      }
    },
    getAttainmentLabel(id) {
      let label = ""

      this.$store.state.hs_types.forEach(function(hs_type){
          if (hs_type.id === id) label = hs_type.value+""
      })
      return label
    },
    getFormType(id) {
      let form = null
      this.$store.state.form_types.forEach(function(form_type){
        if (form_type.id == id) form = form_type
      })
      return form
    },
    setSocialCategories() {
      this.form.social_categories = this.social_categories
    },
    setStrands(strands){
      this.strands = strands;

      if (this.form.strand){
        this.form.strand_id = this.form.strand.id;
      }
    },
    handleChangeStrand() {
      console.log(this.is_other_strand)
      if(this.is_other_strand) {
        this.origin_strand = this.form.strand
        this.form.strand = null
      } else {
        this.form.strand = this.origin_strand
        this.origin_strand = null
      }
    //  TODO: J1 strand exist error must remain in current state
    },
    getStrands(){
      // if (this.$store.state.strands.length) {
      //   this.setStrands(this.$store.state.strands);
      // } else {
        Strand.get({
          params: { page: 1, per_page: 999 },
        }).then(result => {
          if (result) {
            this.$store.state.strands = result.data.data;
            this.setStrands(result.data.data);
          } 
        }).catch( error => {
          console.error(error)
        })
      // }
    },
  },
};
</script>
