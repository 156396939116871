<template>
  <div class="" style="padding: 20px;">
    <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
          .
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-card>
              <div style="width: 200px">
                <img :src="require('@/assets/images/vsu_logo_green.png')"  style="margin-bottom:20px;width: 100%;">
              </div>
              <hr>
              <h3>Welcome <strong>Applicant</strong></h3>
              <el-row :gutter="20" style="padding: 10px">
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    COMPLETE NAME
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <strong> {{ getFullname() }}</strong>
                  </el-col>
              </el-row>

              <el-row :gutter="20" style="padding: 10px">
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    SCHEDULE
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <strong> {{ getScheduleText() }}</strong>
                  </el-col>
              </el-row>

              <el-row :gutter="20" style="padding: 10px" v-if="guestCopy.status === 3">
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    Moodle Link
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <strong> {{ guestCopy.applicant_exam_detail.moodle_link }}</strong>
                  </el-col>
              </el-row>

              <el-row :gutter="20" style="padding: 10px" v-if="guestCopy.status === 3">
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    Moodle Username
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <strong> {{ guestCopy.applicant_exam_detail.moodle_username }}</strong>
                  </el-col>
              </el-row>

              <el-row :gutter="20" style="padding: 10px" v-if="guestCopy.status === 3">
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    Moodle Password
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <strong> {{ guestCopy.applicant_exam_detail.moodle_password }}</strong>
                  </el-col>
              </el-row>

              <el-row :gutter="20" style="padding: 10px">
                  <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    STATUS
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <el-alert
                        :type="getAlertType(guestCopy.final_status)"
                        :closable="false"
                        effect="dark">
                        <div>{{ guestCopy.final_status }}</div>
                      </el-alert>
                  </el-col>
              </el-row>

          
              <el-link type="primary" @click="logout()" :underline="false" style="padding: 20px;
    float: right;
    font-size: 18px;"><i class=" el-icon-logout"></i> Logout</el-link>
            </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">

        </el-col>
    </el-row>
  </div>
</template>

<script>

import { Applicant } from '@/api_services/applicant'
export default {
  name: 'GuestApplicantPreview',
  props:['guest'],
  data() {
    return {
      list: [],
      token: '',
      guestCopy: null
    };
  },
  created: function() {

    this.guestCopy = this.guest; // to avoid mutation
    this.list = [
      {id: "COMPLETE NAME", value: this.guestCopy.firstname+" "+this.guestCopy.middlename+" "+this.guestCopy.lastname+" "+this.guestCopy.extension},
      {id: "SCHEDULE", value: this.getScheduleText() },
      {id: "STATUS", value: this.guestCopy.final_status}
    ];
    // this.token = this.guest['token']
    this.reload()
  },
  methods: {
    logout() {
      this.$store.commit('saveGuest', null)
      this.$emit('update:guest', null)
    },
    reload() {
      console.log('test')
      Applicant.verifyToken({
        email: this.guestCopy.email,
        token: this.guestCopy.token
      })
        .then(result => {
          
          this.loading = false
          const guest = result.data.data;
          guest['token'] = this.guestCopy.token;
          this.$store.commit('saveGuest', guest)
          //  this.guest = this.$store.state.guest
          this.guestCopy = guest;
        })
        .catch( error => {
          console.log('here')
          this.loading = false
          if (error.status === 401) {
            this.$message({
              message: "Invalid credential",
              type: 'error',
              duration: 3000
            });
          }
        })
    },
    getFullname() {
      return this.guestCopy.firstname+" "+this.guestCopy.middlename+" "+this.guestCopy.lastname+" "+ (this.guestCopy.extension || '')
    },
   getScheduleText() {
      if (this.guestCopy.applicant_exam_detail && this.guestCopy.applicant_exam_detail.schedule) {
        return this.showSchedule(this.guestCopy.applicant_exam_detail.schedule)
      }
      return 'N/A'
   },
   showSchedule(schedule) {
      let start = schedule.time_start.split(":")[0]+":"+schedule.time_start.split(":")[1];
      let end = schedule.time_end.split(":")[0]+':'+schedule.time_end.split(":")[1];
      
      return schedule.date+" | "+start+' - '+end;
    },
    getAlertType(final_status) {
      let success = [ 'ACCEPTED','QUALIFIED']
      let warning = ['READY FOR REVIEW']
      let error = ['REJECTED']
      if (success.includes(final_status)) return 'success'
      if (warning.includes(final_status)) return 'warning'
      if (error.includes(final_status)) return 'error'
    },
  }
}
</script>

<style scoped>

</style>


